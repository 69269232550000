class AppModule {
	
	/**
	 * Конструктор
	 */
	constructor() {
		// состояние загрузки 
		this.app_isLoading_state = false;
		
		// состояние загрузки без спиннера
		this.app_isLoading_state_without_spinner = false;
	}
	
	
	/**
	 * Загрузка состояния загрузки
	 */
	getLoadingState()
	{
		return this.app_isLoading_state
	}
	
	
	
	/**
	 * Загрузка состояния загрузки без спиннера
	 */
	getLoadingStateWithoutSpinner()
	{
		return this.app_isLoading_state_without_spinner
	}
	
	
	
	/**
	 * Установка состояния загрузки
	 */
    setLoadingState(value)
	{
		this.app_isLoading_state = value
	}
	
	/**
	 * Установка состояния загрузки без спиннера
	 */
	setLoadingStateWithoutSpinner(value)
	{
		this.app_isLoading_state_without_spinner = value
	}
}

let state = new AppModule();

const getters = {};
const actions = {};
const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
