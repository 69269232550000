<template>
  <div class="container text-center pt-4">
		<div class="col">
			<GoodsTitle value="goods:offers:list"/>
			<GoodsTypeName class="pb-5"/>
			<div class="col pb-4">
				<div class="container d-grid px-0 pb-5">
					<!-- Goods -->
					<div class="col mb-3 text-success">
						<label for="">Товар</label>
					</div>
					<div class="col mb-5 col-md-8 col-lg-6 mx-auto">
						<div class="col">
							<div class="col d-flex justify-content-center">
								<div class="col rounded">
									<div class="mb-3">
										<img
											src="/assets/kartofel-1.jpg"
											class="img-fluid border rounded shadow-sm 
											goods_list__image"
											alt=""
											@load="imgOnload()"
										/>
										<div v-if="!image_loaded" class="loading-wheel"></div>
									</div>
									<div class="">
										<p class="text-center mb-0">
											{{tradeModel.goods.name_ru}}
										</p>
										<p class="text-center mb-0">
											{{tradeModel.goods.vendor_code}}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col mb-3 text-success">
						<label for="">Предложения товара</label>
					</div>
					<div 
						class="col d-flex flex-wrap" 
					>
						<div 
							v-show="this.tradeModel.offers.length == 0"
							class="col text-center text-muted"	
						>
							У данного товара нет предложений.
						</div>
						<div 
							class="col-6 d-flex justify-content-center"
							v-for="offer in this.tradeModel.offers"
							:key="offer.pkid"
						>
							<div class="col-10 pb-3 rounded">
								<router-link
									:to="{
										name: 'goods:offer:edit',
										params: {
											type_id: this.tradeModel.getTypeId(),
											goods_pkid: offer.goods_pkid,
											offer_pkid: offer.pkid,
										},
										query: {
											account: this.authModel.getCurrentAccount(),
											lang: this.authModel.getSettingsMobiLang(),
										},
									}"
									class="text-decoration-none text-dark"
									role="button"
								>
									<div class="mb-3">
										<img
											src="/assets/kartofel-1.jpg"
											class="img-fluid border rounded 
											shadow-sm goods_list__image"
											alt=""
											style=""
										/>
									</div>
									<div class="">
										<p class="text-center mb-0">{{offer.title}}</p>
									</div>
								</router-link>
							</div>
						</div>
					</div>
					<div class="col my-3">
						<button
							class="btn btn-success"
							@click="addNewOffer()"
						>
							Добавить предложение
						</button>
					</div>
				</div>
			</div>
			<!-- Navigation Bottom Button -->
			<div class="col w-100 gx-0 p-3 bottom-0 position-fixed">
				<!-- hr -->
				<hr class="my-3">
				<div class="col d-flex justify-content-start">
					<button
						@click="goBack()"
						role="button"
						class="btn btn-outline-secondary d-flex align-items-center"
					>
						Назад
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
async function routeInit(type, to, from, next)
{
	let goods_pkid = to.params.goods_pkid
	let type_id = to.params.type_id
	storeInstance.state.app.setLoadingState(true);
	storeInstance.state.trade.clearGoods();
	storeInstance.state.trade.setTypeId(type_id);
	await storeInstance.state.trade.doLoadGoods(goods_pkid);
	await storeInstance.state.trade.doLoadAllOffers(goods_pkid);
	await storeInstance.state.trade.doLoadTypes();
	storeInstance.state.app.setLoadingState(false);
	next()
}
import GoodsTitle from "trade/pages/mobile/goods/GoodsTitle.vue"
export default {
	components: {
		GoodsTitle,
	},
	async beforeRouteEnter(to, from, next)
	{
		await routeInit("beforeRouteEnter", to, from, next);
	},
	async beforeRouteUpdate(to, from, next)
	{
		await routeInit("beforeRouteUpdate", to, from, next);
	},
	data()
	{
		return {
			image_loaded: false,
		}
	},
	methods:
	{
		imgOnload()
		{
			this.image_loaded = true
			window["onIframeClick"]()
		},
		addNewOffer()
		{
			this.$router.push({
				name: 'goods:offer:add',
				params: {
					type_id: this.tradeModel.getTypeId(),
					goods_pkid: this.tradeModel.getGoodsPkid(),
				},
				query: {
					account: this.authModel.getCurrentAccount(),
					lang: this.authModel.getSettingsMobiLang(),
				},
			})
		},
		goBack()
		{
			this.$router.push({
				name: 'goods:edit',
				params: {
					goods_pkid: this.tradeModel.getGoodsPkid(),
				},
				query: {
					account: this.authModel.getCurrentAccount(),
					lang: this.$route.query.lang,
				},
			})
		},
	},
	computed: {
		authModel() 
		{
			return this.$store.state.auth;
		},
		tradeModel() 
		{
			return this.$store.state.trade;
		},
	},
};
</script>

<style lang="scss" scoped>
.goods_list{
	&__image{
		height: 100px;
	}
}
</style>