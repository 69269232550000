import { createStore } from "vuex";
import app from './store/app.js'
import auth from 'io7/store/auth.js'
import trade from 'trade/store/trade.js'

const store = createStore({
	modules: {
		app,
		auth,
		trade
	},
});

export default store;