<template>
	<div class="container-fluid">
		<GoodsTitle value="goods:edit:categories" />
		<div class="col">
			<ul class="list-group">
				<li 
					class="list-group-item py-3 d-flex gap-3"
					v-for="(cat, i) in this.tradeModel.categories"
					:key="i"
				>
					<input 
						type="checkbox"
						ref="catCheckbox"
						@change="updateCategories($event, cat)"
						class="catCheckbox"
						:value="cat"
						:checked="
							this.tradeModel.getGoodsCategories().indexOf(cat.pkid) != -1 
							? true : false
						"
					>
					<span class="">
						{{ cat.name_ru }}
					</span>
				</li>
			</ul>
		</div>
		<div class="row w-100 mx-auto py-3 pt-5">
			<div class="col d-flex justify-content-end">
				<button
					class="btn btn-primary"
					@click="saveChanges()"
				>
					Сохранить
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import GoodsTitle from "trade/pages/mobile/goods/GoodsTitle.vue"
export default {
	components: {
		GoodsTitle, 
	},
	async beforeRouteEnter(to, from, next)
	{
		storeInstance.state.app.setLoadingState(true);
		let type_id = to.params.type_id
		storeInstance.state.trade.setTypeId(type_id);
		if (storeInstance.state.trade.categories == null)
		{
			await storeInstance.state.trade.doLoadCategories();
		}
		if (to.name == 'goods:edit:categories')
		{
			let goods_pkid = to.params.goods_pkid
			if (storeInstance.state.trade.getGoodsPkid() != goods_pkid)
			{
				await storeInstance.state.trade.doLoadGoods(goods_pkid);
			}
		}
		storeInstance.state.app.setLoadingState(false);
		next()
	},
	async beforeRouteUpdate(to, from, next)
	{ 
		storeInstance.state.app.setLoadingState(true);
		let type_id = to.params.type_id
		storeInstance.state.trade.setTypeId(type_id);
		if (storeInstance.state.trade.categories == null)
		{
			await storeInstance.state.trade.doLoadCategories();
		}
		if (to.name == 'goods:edit:categories')
		{
			let goods_pkid = to.params.goods_pkid
			if (storeInstance.state.trade.getGoodsPkid() != goods_pkid)
			{
				await storeInstance.state.trade.doLoadGoods(goods_pkid);
			}
		}
		//storeInstance.state.trade.getGoods();
		storeInstance.state.app.setLoadingState(false);
		next()
	},
	methods:
	{
		updateCategories(event, cat)
		{
			let goods_categories = this.tradeModel.getGoodsCategories()
			let isChecked = event.target.checked
			let existingCategory = null
			if(goods_categories != undefined 
				|| goods_categories != null)
			{
				existingCategory = goods_categories.find(el => {
					return el == cat.pkid
				})
			}
			
			if(existingCategory != null && isChecked==false)
			{
				this.tradeModel.unsetGoodsCategory(cat.pkid)
				if(cat['selected']) cat['selected'] = false
			}
			else
			{
				this.tradeModel.setGoodsCategory(cat.pkid);
			}
		},
		saveChanges()
		{
			let query = {
				account: this.authModel.getCurrentAccount(),
				lang: this.$route.query.lang,
			}
			if(this.tradeModel.isAdd())
			{
				this.$router.push({
					name: "goods:add",
					params: {
						type_id: this.tradeModel.getTypeId()
					},
					query: query
				})
			}
			else
			{
				this.$router.push({
					name: "goods:edit:info",
					params: {
						goods_pkid: this.tradeModel.getGoodsPkid(),
						type_id: this.tradeModel.getTypeId()
					},
					query: query
				})
			}
		},
	},
	computed:
	{
		authModel() 
		{
			return this.$store.state.auth
		},
		tradeModel()
		{
			return this.$store.state.trade
		}
	}
};
</script>

<style>
.catCheckbox
{
	width: 25px;
	height: 25px;
}
</style>
