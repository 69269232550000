<template>
	<div class="">
		<div 
			v-if="appModule.getLoadingState()" 
			class="loading style d-flex justify-content-center align-items-center">
				<img 
					v-show="!appModule.getLoadingStateWithoutSpinner()"
					src="/assets/loading.png" 
					class="m-t-large fa-spin my-loading-wheel" 
					alt="" 
				/>
		</div>
		<router-view/>
	</div>
</template>

<script>
export default {
	name: "App",
	mounted()
	{
		window["appComponent"] = this;
	},
	computed:
	{
		appModule()
		{
			return this.$store.state.app
		}
	}
};
</script>

<style></style>
