import lib from "io7/lib";

class TradeModule {
	/**
	 * Конструктор
	 */
	constructor() 
	{
		// Все товары
		this.all_goods = null;
		this.page_limit = 10;
		this.current_page = 0;
		this.total_pages = 0;
		this.search_text = "";
		
		// Все типы 
		this.types = null;
		
		// Все категории 
		this.categories = null;
		
		// Все предложения
		this.offers = null;
		
		// Все ед измерения
		this.units = null;
		
		// Описание характеристик редактируемого товара или услуги
		/**
		 * [{
		 *		"pkid":"1",
		 *		"cabinet_pkid":null,
		 *		"type":"1",
		 *		"meta":{ "name":{ "ru":"Цвет"},"description":{"ru":"Цвет"}},
		 *		"slug":"",
		 *		"code_1c":"",
		 *		"type_pkid":"6",
		 *		"param_pkid":"1",
		 *		"param_type":"1",
		 *		"is_required":"0",
		 * 		"values":[
		 *			{
		 *				"pkid":"14",
		 *				"param_pkid":"1",
		 *				"meta":{"name":{"ru":"Синий"},"description":{"ru":"Синий"}},
		 *				"slug":"",
		 *				"code_1c":"",
		 *			},
		 *		]
		 *	}]
		 */
		this.goods_params_info = null;
		
		// Редактируемый товар
		this.goods = {};
		
		// ID товара, которая ставится при doLoadGoods(goods_pkid)
		this.goods_pkid = null;
		
		// ID выбранного типа товара
		this.type_id = null;
		
		// Редактируемое предложение
		this.offer = {};
		
		// ID товара, которая ставится при doLoadOffer(offer_pkid)
		this.offer_pkid = {};
	}
	
	
	
	/**
	 * Очищает модель
	 */
	clear()
	{
		this.clearGoods();
		this.type_id = null;
		this.goods_params_info = null;
		this.types = null;
	}
	
	
	
	/**
	 * Очищает только товары
	 */
	clearGoods()
	{
		this.goods_pkid = null;
		this.goods = {
			"pkid": null,
			"meta": {
				"name":{
					"ru": "",
					"en": "",
					"kz": "",
				},
				"description":{
					"ru": "",
					"en": "",
					"kz": "",
				},
			},
			"categories": [],
			"params_values": [],
			"photos": []
		};
	}
	
	
	
	/**
	 * Очищает только предложение
	 */
	clearOffer()
	{
		this.offer = {
			"goods_pkid": null,
			"title": null,
			"offer_number": null,
			"unit_pkid": null,
			"min_order_unit_count": null,
			"price_per_unit_kzt": null,
			"type": null,
			"meta": {},
			"params_values": [],
			"photos": [],
		};
	}
	
	
	
	/**
	 * Добавляется товар
	 */
	isAdd()
	{
		return this.goods_pkid == null;
	}
	
	
	
	/**
	 * Добавляется предложение
	 */
	isOfferAdd()
	{
		return this.offer.offer_number == null;
	}
	
	
	
	/**
	 * Редактируется товар
	 */
	isEdit()
	{
		return this.goods_pkid != null;
	}
	
	
	
	/**
	 * Редактируется предложение
	 */
	isOfferEdit()
	{
		return this.offer.offer_number != null;
	}
	
	
	
	/**
	 * Товар не найден в базе
	 */
	isNotFound()
	{
		return this.isEdit() && this.goods.pkid == null;
	}
	
	
	/* Работа с типами */
	
	/**
	 * Загрузка всех типов через API
	 */
	async doLoadTypes() 
	{
		const response = await lib.ioCallAction({ entity: "app.api.goods.getTypes" });
		if (
			response &&
			response.error_code == 1 &&
			typeof response.types == "object"
		) 
		{
			this.types = response.types;
		}
	}
	
	
	
	/**
	 * Выгрузка всех типов 
	 */
	getTypes() 
	{
		if (this.types != "" || this.types != undefined || this.types != null)
		{
			return this.types;
		}
		return null;
	}
	
	
	
	/**
	 * Выгрузка ID выбранного типа
	 */
	getTypeId() 
	{
		if (this.type_id != null) return this.type_id;
		return 0;
	}
	 
	 
	 
	/**
	 * Установка ID выбранного типа
	 */
	setTypeId(type_id) 
	{
		this.type_id = type_id;
	}
	
	
	
	
	/* Работа с категориями */
	
	/**
	 * Загрузка всех категорий через API
	 */
	async doLoadCategories()
	{
		const response = await lib.ioCallAction({ entity: "app.api.goods.getCategories" });
		if (
			response &&
			response.error_code == 1 &&
			typeof response.categories == "object"
		) 
		{
			this.categories = response.categories;
		}
	}
	
	
	
	/**
	 * Выгрузка всех категорий
	 */
	getCategories() 
	{
		if (this.categories != undefined 
			|| this.categories != null)
		{
			return this.categories;
		}
		return null;
	}
	
	
	
	/**
	 * Получает категорию по pkid
	 */
	getCategoriesByPkid(category_pkid) 
	{
		let category = null;
		//this.categories
		let item = this.categories.find(el => {
			return el.pkid == category_pkid
		})
		if (item)
		{
			category = {
				category_name: item.name_ru,
				category_pkid: category_pkid,
			}
		}
		return category;
	}
	
	
	
	/**
	 * Выгрузка категорий редактируемого товара
	 */
	getGoodsCategories() 
	{
		if (this.goods === null) return null;
		if (this.goods.categories === null) return null;
		return this.goods.categories;
	}
	
	
	
	/**
	 * Устанавливает категорию редактируемого товара
	 */
	setGoodsCategory(pkid)
	{
		if (this.goods === null) return;
		if (this.goods.categories === null) return;
		
		let item = this.goods.categories.find(el => {
			return el == pkid
		})
		if (!item)
		{
			this.goods.categories.push(pkid);
		}
	}
	
	
	
	/**
	 * Удаляет категорию редактируемого товара
	 */
	unsetGoodsCategory(pkid)
	{
		if (this.goods === null) return;
		if (this.goods.categories === null) return;
		
		let item = this.goods.categories.find(el => {
			return el == pkid
		})
		if (item)
		{
			let new_categories = this.goods.categories.filter(el => {
				return el != pkid
			})
			this.goods.categories = new_categories
		}
	}
	
	
	/* Работа с характеристиками (параметрами) */
	
	/**
	 * Загрузка характеристик товара через API
	 */
	async doLoadParams() 
	{
		const response = await lib.ioCallAction({
			entity: "app.api.goods.getParamsByTypeID",
			params: {
				type_id: this.getTypeId(),
			},
		});
		if (
			response &&
			response.error_code == 1 &&
			typeof response.params == "object"
		) 
		{
			this.goods_params_info = response.params;
		}
	}

	
	
	/**
	 * Редактирует (стр. goods_edit_params) параметры товара
	 */
	async doGoodsSaveParams() 
	{
		if (this.goods == null) return;
		let response = await lib.ioCallAction({
			entity: "app.api.goods.saveParams",
			params: {
				vendor_code: this.goods.vendor_code,
				manufacturer: this.goods.manufacturer,
				goods_pkid: this.goods.pkid,
				goods_params: this.goods.params_values,
			},
		});
		return response;
	}

	
	 
	/**
	 * Получает значение параметра, если список
	 */
	getParamsValuePkid(param_pkid, param_type)
	{
		// Если товар
		if(param_type == 1)
		{
			if (this.goods === null) return "";
			if (this.goods.params_values == null) return "";
			
			let found_item = this.goods.params_values.find(el => {
				return el.param_pkid == param_pkid
			})
			
			if(found_item)
			{
				return found_item.value_pkid
			}
		}
		else
		{
			if (this.offer === null) return "";
			if (this.offer.params_values == null) return "";
			
			let found_item = this.offer.params_values.find(el => {
				return el.param_pkid == param_pkid
			})
			
			if(found_item)
			{
				return found_item.value_pkid
			}
		}

		return ""
	}
	
	
	
	/**
	 * Устанавливает значение параметра товара для списка
	 */
	setParamsValuePkid(param_pkid, value_pkid, param_type)
	{	
		let param = {
			'param_pkid': param_pkid,
			'value_pkid': value_pkid,
			'value': ''
		}
		
		// Если товар
		if(param_type == 1)
		{
			if (this.goods === null) return "";
			if (this.goods.params_values == null) return "";
			
			let item = this.goods.params_values.find(el => {
				return el.param_pkid == param_pkid
			})
			
			if (item && item.value_pkid != value_pkid && item.value == '')
			{
				item.value_pkid = value_pkid
			}
			else
			{
				this.goods.params_values.push(param)
			}
		}
		else
		{
			if (this.offer === null) return "";
			if (this.offer.params_values == null) return "";
		
			let item = this.offer.params_values.find(el => {
				return el.param_pkid == param_pkid
			})
			
			if (item && item.value_pkid != value_pkid && item.value == '')
			{
				item.value_pkid = value_pkid
			}
			else
			{
				this.offer.params_values.push(param)
			}
		}
	}
	
	
	
	/**
	 * Получает значение параметра, если текст
	 */
	getParamsValueText(param_pkid, param_type)
	{
		// Если товар
		if(param_type == 1)
		{
			if (this.goods === null) return "";
			if (this.goods.params_values == null) return "";
			
			let found_item = this.goods.params_values.find(el => {
				return el.param_pkid == param_pkid
			})
			
			if(found_item && found_item.value_pkid == null)
			{
				return found_item.value
			}
		}
		else
		{
			if (this.offer === null) return "";
			if (this.offer.params_values == null) return "";
			
			let found_item = this.offer.params_values.find(el => {
				return el.param_pkid == param_pkid
			})
			
			if(found_item && found_item.value_pkid == null)
			{
				return found_item.value
			}
		}
		
		return ''
	}
	
	
	
	/**
	 * Устанавливает значение параметра товара для текста
	 */
	setParamsValueText(param_pkid, value, param_type)
	{
		
		let param = {
			'param_pkid': param_pkid,
			'value': value,
			'value_pkid': null
		}
		
		// Если товар
		if(param_type == 1)
		{
			if (this.goods === null) return "";
			if (this.goods.params_values == null) return "";
			let item = this.goods.params_values.find(el => {
				return el.param_pkid == param_pkid
			})
			
			if (item && item.value != value)
			{
				item.value = value
			}
			else
			{
				this.goods.params_values.push(param)
			}
		}
		else
		{
			if (this.offer === null) return "";
			if (this.offer.params_values == null) return "";
			
			let item = this.offer.params_values.find(el => {
				return el.param_pkid == param_pkid
			})
			
			if (item && item.value != value)
			{
				item.value = value
			}
			else
			{
				this.offer.params_values.push(param)
			}
		}
	}
	
	
	
	/* Работа с товаром */
	
	/**
	 * Загрузка всех товаров через API
	 */
	async doLoadAllGoods() 
	{
		if(this.current_page != 0) this.current_page = this.current_page - 1
		const response = await lib.ioCallAction({
			entity: "app.api.goods.search",
			params: {
				"start": this.current_page * this.page_limit,
				"limit": this.page_limit,
				"search_text": this.search_text,
			},
		});
		if (
			response &&
			response.error_code == 1 &&
			typeof response.items == "object"
		) 
		{
			this.all_goods = response.items;
			this.total_pages = (this.page_limit > 0) ? Math.ceil(response.total / this.page_limit) : 1;
		}
		return response;
	}
	 
	
	
	/**
	 * Загрузка товара через API
	 */
	async doLoadGoods(goods_pkid) 
	{
		const response = await lib.ioCallAction({
			entity: "app.api.goods.getByID",
			params: {
				goods_pkid: goods_pkid,
			},
		});
		if (
			response &&
			response.error_code == 1 &&
			typeof response.goods == "object"
		) 
		{
			this.goods = response.goods;
			this.goods_pkid = response.goods.pkid;
			this.type_id = response.goods.type_id;
		}
		return response;
	}
	
	
	
	/**
	 * Добавляет (стр. goods_add) или редактирует (стр. goods_edit_info) товар
	 */
	async doGoodsSaveInfo() 
	{
		let response = lib.ioCallAction({
			entity: "app.api.goods.saveInfo",
			params: this.goods,
		});
		
		return response;
	}

	
	
	/**
	 * Выгрузка товара
	 */
	getGoods() 
	{
		if (this.goods != undefined || this.goods != null)
		{
			return this.goods;
		}
		return null;
	}
	
	
		
	/**
	 * Выгрузка ID выбранного типа
	 */
	getGoodsPkid() 
	{
		if (this.goods_pkid != null) return this.goods_pkid;
		return null;
	}
	
	
	
	/**
	 * Удаление товара
	 */
	async deleteGoods() 
	{
		let response = await lib.ioCallAction({
			entity: "app.api.goods.deleteGoods",
			params: {
				"pkid": this.getGoodsPkid(),
			}
		});
		
		if(response.error_code == 1)
		{
			this.clearGoods()
			return true
		}
		
		return false;
	}
	
	
	
	/* Работа с предложением */
	
	/**
	 * Загружает редактируемое предложение товара
	 */
	async doLoadOffer(offer_pkid) 
	{
		let response = await lib.ioCallAction({
			entity: "app.api.goods.getOffer",
			params: {
				"goods_pkid": this.getGoodsPkid(),
				"pkid": offer_pkid,
			}
		});
		
		if(response.error_code == 1)
		{
			this.offer = response.offer
			this.goods_pkid = this.offer.goods_pkid
		}
		
		return response;
	}
	
	
	/**
	 * Загружает все предложения товара
	 */
	async doLoadAllOffers(goods_pkid) 
	{
		let response = await lib.ioCallAction({
			entity: "app.api.goods.getOffersByGoodsPkid",
			params: {
				"goods_pkid": goods_pkid,
			}
		});
		
		if(response.error_code == 1)
		{
			this.offers = response.offers
			this.goods_pkid = goods_pkid
		}
		
		return response;
	}
	
	
	/**
	 * Добавляет или редактирует предложение товара
	 */
	async doOfferSave() 
	{
		this.offer["goods_pkid"] = this.getGoodsPkid()
		let response = await lib.ioCallAction({
			entity: "app.api.goods.saveOffer",
			params: this.offer,
			form: null,
		});
		
		return response;
	}
	
	
	
	/**
	 * Удаление предложения
	 */
	async deleteOffer() 
	{
		let response = await lib.ioCallAction({
			entity: "app.api.goods.deleteOffer",
			params: {
				"pkid": this.offer.pkid,
				"goods_pkid": this.offer.goods_pkid,
			}
		});
		
		if(response && response.error_code == 1)
		{
			this.clearOffer()
			return true
		}
		
		return false;
	}
	
	/* Работа с параметрами предложения */
	
	/**
	 * Загружает все единицы измерения 
	 */
	async doLoadUnits(goods_pkid) 
	{
		let response = await lib.ioCallAction({
			entity: "app.api.goods.getUnits",
		});
		
		if(response.error_code == 1)
		{
			this.units = response.units
		}
		
		return response;
	}
	
	/* Работа с фото */
	
	/**
	 * Загружает фото в облако + lib_photos, проходит валидацию на являемость фото
	 */
	async upload_photo(photos)
	{
		let form = new FormData()
		for (let i=0; i < photos.length; i++)
		{
			form.append('photo' + '[' + i + ']', photos[i]);
		}
		
		let response = await lib.ioCallAction({
			entity: "app.api.goods.uploadPhoto",
			form: form,
			params: {
				//"test": 1,
			},
		});
		
		if(response && response.error_code == 1 && typeof response.photos == 'object')
		{
			/** 
			 * response.photos = 
			 * {
			 * 		photo_pkid: {photo_info},
			 * } 
			 */
			let received_photos = Object.values(response.photos)
			return received_photos
		}
		
		return null;
	}
	
	
	
	/**
	 * Устанавливает фотки редактируемого товара
	 */
	setGoodsPhotos(photos)
	{
		if (this.goods.photos == undefined || this.goods.photos == null) return 
		
		let item = {
			pkid: 0,
			orig_url: ''
		}
		
		let all_photos = this.goods.photos
		let new_photos = []
		
		// найти новые фотки, которых еще нет у редактируемого товара
		new_photos = photos.filter(e => all_photos.find(obj => obj.pkid === e.pkid) === undefined);
		
		// отфильтрованный new_photos с уникальными объектами
		let flags = {};
		let new_photos_filtered = []
		if(new_photos != undefined && new_photos.length != 0)
		{
			new_photos_filtered = new_photos.filter(el => {
				if (flags[el.pkid]) {
					return false;
				}
				flags[el.pkid] = true;
				return true;
			});
		}
		
		// Сохранить фото у редактируемого товара
		if(new_photos_filtered != undefined && new_photos_filtered.length != 0)
		{
			new_photos_filtered.forEach(np => this.goods.photos.push(np))
		}
		
		return this.goods.photos
	}
	
}

let state = new TradeModule();

const getters = {};
const actions = {};
const mutations = {};

export default 
{
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
