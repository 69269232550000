<template>
 <div id="wrap">
	<div id="ulogo">
	 <a
		href="//devkdt.info"
		class="ulogo d-flex justify-content-center"
		title="KDT"
		target="_blank"
		rel="nofollow noopener noreferrer"
		>
		<img src="/assets/ulogo.png" alt="ulogo-kdt"
         class="image-fluid"
         style=""/>
		
	</a>
	 <span class="error-title sitenotfound">404 ошибка</span>
	 <a
		href="//cms.devkdt.info"
		class="ulogo2 d-flex justify-content-center"
		title="BMC CMS"
		target="_blank"
		rel="nofollow noopener noreferrer"
	>
		<img src="/assets/ulogo2.png" alt="ulogo2-bmc-cms"
         class="image-fluid"
         style=""/>
	</a>
	</div>
	<div id="ulogo-line"></div>
	<div id="uerror" class="mt-4">
	 <p id="uerror-link" class="sitenotfound my-4">Сайт не найден</p>
	 <p id="error-descr" class="">
		Запрашиваемый вами сайт "sso.devkdt.info"<br />не зарегистрирован в системе
		создания сайтов
		<a
		 href="//cms.devkdt.info"
		 target="_blank"
		 rel="nofollow noopener noreferrer"
		 >BMC CMS</a
		>.
	 </p>
	 <hr />
	 <p id="error-descr">
		Если вы уверены, что тут точно должен быть Ваш сайт,<br />свяжитесь с нами
		либо по электронной почте
		<a href="mailto:info@kdt.kz">info@kdt.kz</a>,<br />
		либо через
		<a
		 href="http://ticket.kdt.kz"
		 target="_blank"
		 rel="nofollow noopener noreferrer"
		 >службу технической поддержки</a
		>.
	 </p>
	</div>
 </div>
</template>

<script>
export default {};
</script>

<style scoped>
#wrap #ulogo {
 position: relative;
 width: 100%;
 height: 27px;
 margin: 0 auto;
 padding: 48px 0 0 0;
 z-index: 5;
}

#wrap {
 min-height: 92vh;
 line-height: 1;
 background: #fff;
 font-family: Verdana, Geneva, sans-serif;
 color: #686868;
 font-size: 14px;
 line-height: 16px;
 position: relative;
 width: 100%;
}

#wrap #ulogo-line {
 position: absolute;
 width: 100%;
 height: 1px;
 background: #92c9ff;
 top: 62px;
 left: 0;
 z-index: 1;
}

#wrap .ulogo {
 position: absolute;
 top: 48px;
 left: calc(30% - 14px);
 background: #fff no-repeat 50% 0;
 width: 62px;
 height: 27px;
 font-size: 0;
 color: transparent;
 text-decoration: none;
}

#wrap .error-title.sitenotfound {
 width: 140px;
 margin-left: -70px;
}

#wrap .error-title {
 position: absolute;
 width: 76px;
 top: 52px;
 left: 50%;
 margin-left: -38px;
 text-align: center;
 background: #fff;
 font-size: 18px;
 line-height: 18px;
 color: #5191d1;
}

#wrap #uerror {
 width: 100%;
 margin: 0 auto;
 padding: 42px 0 0 0;
}

#wrap #uerror-link {
 font-size: 22px;
 line-height: 26px;
 text-transform: uppercase;
 text-align: center;
 color: #5191d1;
}

#wrap #error-descr {
 font-size: 14px;
 line-height: 25px;
 color: #686868;
 text-align: center;
 margin: 20px auto 20px auto;
 width: 700px;
}

#wrap .ulogo2 {
 position: absolute;
 top: 48px;
 right: 30%;
 background: #fff no-repeat 50% 0;
 width: 48px;
 height: 27px;
 font-size: 0;
 color: transparent;
 text-decoration: none;
}
</style>
