import { createRouter, createWebHistory } from "vue-router";
import HomeDefault from "trade/pages/HomeDefault.vue";
import HomeMobile from "trade/pages/HomeMobile.vue";

import InDev from "trade/pages/mobile/InDev.vue"

import GoodsAddSelectTypePage from "trade/pages/mobile/goods/GoodsAddSelectTypePage.vue"
import GoodsCategoriesPage from "trade/pages/mobile/goods/GoodsCategoriesPage.vue"
import GoodsSaveInfoPage from "trade/pages/mobile/goods/GoodsSaveInfoPage.vue"
import GoodsOfferSavePage from "trade/pages/mobile/goods/GoodsOfferSavePage.vue"
import GoodsOffersListPage from "trade/pages/mobile/goods/GoodsOffersListPage.vue"
import GoodsListPage from "trade/pages/mobile/goods/GoodsListPage.vue"
import GoodsShowItemPage from "trade/pages/mobile/goods/GoodsShowItemPage.vue"

const routes = [
	{
		path: "/",
		name: "home:default",
		component: HomeDefault
	},
	{
		path: "/mobile/",
		name: "home:mobile",
		component: HomeMobile,
		props: true,
	},
	
	/* В разработке */
	{
		path: "/mobile/in-dev",
		name: "home:inDev",
		component: InDev,
		props: true,
		meta: {
			backUrlName: "home:mobile",
		},
	},
	
	/* Товары */
	{
		path: "/mobile/goods/",
		name: "goods:list",
		component: GoodsListPage,
		props: true,
		meta: {
			backUrlName: "home:mobile",
		},
	},
	{
		path: "/mobile/goods/add/",
		name: "goods:add:select_type",
		component: GoodsAddSelectTypePage,
		props: true,
		meta: {
			backUrlName: "home:mobile",
		},
	},
	{
		path: "/mobile/goods/add/:type_id/categories",
		name: "goods:add:categories",
		component: GoodsCategoriesPage,
		props: true,
		meta: {
			backUrlName: "goods:add",
		},
	},
	{
		path: "/mobile/goods/edit/:goods_pkid/categories",
		name: "goods:edit:categories",
		component: GoodsCategoriesPage,
		props: true,
		meta: {
			backUrlName: "goods:edit:info",
		},
	},
	{
		path: "/mobile/goods/add/:type_id",
		name: "goods:add",
		component: GoodsSaveInfoPage,
		props: true,
		meta: {
			backUrlName: "goods:add:select_type",
		},
	},
	{
		path: "/mobile/goods/edit/:goods_pkid/info",
		name: "goods:edit:info",
		component: GoodsSaveInfoPage,
		props: true,
		meta: {
			backUrlName: "goods:edit",
		},
	},
	{
		path: "/mobile/goods/edit/:goods_pkid",
		name: "goods:edit",
		component: GoodsShowItemPage,
		props: true,
		meta: {
			backUrlName: "goods:list",
		},
	},
	{
		path: "/mobile/goods/:goods_pkid/offers",
		name: "goods:offers:list",
		component: GoodsOffersListPage,
		props: true,
		meta: {
			backUrlName: "goods:edit",
		},
	},
	{
		path: "/mobile/goods/edit/:goods_pkid/offer/add",
		name: "goods:offer:add",
		component: GoodsOfferSavePage,
		props: true,
		meta: {
			backUrlName: "goods:edit",
		},
	},
	{
		path: "/mobile/goods/edit/:goods_pkid/offer/:offer_pkid",
		name: "goods:offer:edit",
		component: GoodsOfferSavePage,
		props: true,
		meta: {
			backUrlName: "goods:offers:list",
		},
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.afterEach((to, from) => {
	window["onUrlChange"]();
	if(to.name == 'goods:add:categories' 
		|| to.name == 'goods:edit:categories'
		|| to.name == 'goods:offer:add'
		|| to.name == 'goods:offer:edit'
		|| to.name == 'goods:add'
		|| to.name == 'goods:edit:info'
		)
	{
		window["scrollToTop"]();
	}
	if((to.name == 'goods:add' || to.name == 'goods:edit:info') 
			&& (from.name == 'goods:add:categories' || from.name == 'goods:edit:categories')
			)
	{
		window["scrollToElement"]('goods_categories');
	}
})

export default router;