import "trade/main.scss"

import { createApp } from "vue"
import store from "./store.js"
import App from "./App.vue"
import router from "./router"
import axios from "axios"
import VueAxios from "vue-axios"
import { createMetaManager, plugin as metaPlugin } from "vue-meta"
import Toggle from "@vueform/toggle"
import { FontAwesomeIcon } from "trade/plugins/font-awesome"
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "./css/app.css"
import "trade/css/opt-mobile.css"
import lib from "io7/lib"
import ModalWindow from "trade/components/ModalWindow.vue"
import MobileBottomButtons from "trade/components/MobileBottomButtons.vue"
import GoodsTitle from "trade/pages/mobile/goods/GoodsTitle.vue"
import GoodsTypeName from "trade/pages/mobile/goods/GoodsTypeName.vue"

const app = createApp(App);

const metaManager = createMetaManager();

window["appInstance"] = null;
window["appComponent"] = null;
window["storeInstance"] = null;
window["appInstance"] = app;
window["storeInstance"] = store;
window["lib"] = lib;
window["axios"] = axios;
window['router'] = router

app.config.globalProperties.$store = store;
app.use(router);
app.use(store);
app.use(VueAxios, axios);
app.use(metaManager);
app.use(Toggle);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component('ModalWindow', ModalWindow);
app.component('MobileBottomButtons', MobileBottomButtons);
app.component('GoodsTitle', GoodsTitle);
app.component('GoodsTypeName', GoodsTypeName);
app.mount("#app");

function addRecaptcha() {
	var s = document.createElement("script");
	s.setAttribute(
		"src",
		"https://www.google.com/recaptcha/api.js?onload=recaptchaApiLoaded&render=explicit"
	);
 	//s.onload=recaptchaApiLoaded;
	document.body.appendChild(s);
}

window["recaptchaApiLoaded"] = function () {
	let register_recaptcha = document.querySelector(".register_recaptcha");
	if (register_recaptcha) {
		window["grecaptcha"].render(register_recaptcha, {
		sitekey: "6LfyFJQeAAAAANTV8mALc9FqPCa04WzlpVfSM5xN",
		});
 	}
};

addRecaptcha();

/* Запускаем скрипт проверки авторизации */
setTimeout(() => {
	storeInstance.state.auth.SSI_step1();
}, 100);



