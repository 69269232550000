import axios from "axios";
import lib from "io7/lib";

class Auth {
	
	/**
	 * Конструктор
	 */
	constructor()
	{
		/* Инициализация */
		this.userProfile = null;
		this.selectedCabPkid = null;
		this.selectedCabInfo = null;
        
		/* Создание userProfile */
		if (app_settings.JWT_TOKEN != null)
		{
			this.setUserProfile(Object.assign({}, app_settings.JWT_TOKEN));
		}
		
		/* Устанавливаем текущий кабинет */
		this.setSelectedCabPkidByAccount( app_settings.current_account );
        this.iframe_ref = null
	}


    
	/**
	 * Делает авторизацию
	 * Возвращает true если правильный логин и пароль.
	 * Также устанавливает авторизационную куку.
	 */
	async doLogin(login, password)
	{
		const payload = new FormData();
		payload.append("login", login);
		payload.append("password", password);

		const response = await axios
			.post(
				"https://" +
					app_settings.ioProjects["com.sso"] +
					"/auth/check.password.php?format=json",
				payload,
				{
					withCredentials: true,
					credentials: "include",
				}
			)
			.catch((err) => {
				console.log(err);
			});
		
		if (response &&
			typeof response.data == "object" &&
			response.data.user_data != undefined &&
			response.data.error_code == 1
		)
		{
			
			lib.setCookie('bmc_jwt', response.data.jwt_string)
			this.setUserProfile(response.data.user_data);
			return true;
		}

		return false;
	}

	
	
	/**
	 * Делает выход
	 */
	async doLogout()
	{
		const payload = new FormData();

		const response = await axios.post(
			"https://" + app_settings.ioProjects["com.sso"] + "/auth/logout.php",
			payload,
			{
				withCredentials: true,
				credentials: "include",
			}
		);
		
		if (response && typeof response.data == "object" && response.data.error_code == 1)
		{
            lib.deleteCookie('current_account')
            lib.deleteCookie('bmc_jwt')
            lib.deleteCookie('bmc_session')
			this.setUserProfile(null);
			return true;
		}

		return false;
	}
	
	
	/* Single Site In и Single Site Out Script */
	
	/**
	 * SSI step 1
	 */
	async SSI_step1()
	{
		if (app_settings.IOPROJECT == "com.mobi") return;
		
		const payload = new FormData();
		const response = await axios.post(
			"https://" + app_settings.ioProjects["com.sso"] + "/auth/check.auth.php",
			payload,
			{
				withCredentials: true,
				credentials: "include",
			}
		);
		
		let is_guest = this.userProfile == null;
		let login_hash = !is_guest ? this.userProfile.login_hash : "";
		
		if (!response) return;
		let responseText = response.data;
		if (responseText == '0' && is_guest) return;
		if (login_hash == responseText) return;
		
		if (responseText == '0')
		{
			console.log("SSI logout");
			await this.doLogout();
		}
		else
		{
			// await this.SSI_step2();
		}
	}
	
	
	
	/**
	 * SSI step 2
	 */
	async SSI_step2()
	{
		if (IOPROJECT == "com.sso") return;
		
		const payload = new FormData();
		const response = await axios.post(
			"https://" + app_settings.ioProjects["com.sso"] + "/auth/create.tmp.token.php",
			payload,
			{
				withCredentials: true,
				credentials: "include",
			}
		);
		
		if (!response) return;
		let responseText = response.data;
		if (responseText == '0') return;
		
		await this.SSI_step3(responseText);
	}
	
	
	
	/**
	 * SSI step 3
	 */
	async SSI_step3(token)
	{
		let response = await lib.ioCallAction
		({
			"entity": "io.model.session.createJW", 
			"params": {
				token: token,
			},
		});
		
		if (!response) return;
		if (!response.error_code != 1) return;
		
		this.setUserProfile(response.user_data);
	}
	
	
	
	/*
	* Запросы к api
	*/
	
	/**
	 * Регистрация пользователя через api
	 * Возращает объект с результатом запроса
	 */
	async doRegisterUser(user_data)
	{
		let params = {};
		params["sname"] = user_data.sname;
		params["fname"] = user_data.fname;
		params["email"] = user_data.email;
		params["tel"] = user_data.tel;
		params["login"] = user_data.myLogin;
		params["toggleValue"] = user_data.toggleValue;
		if (user_data.toggleValue == true) {
			params["password"] = user_data.password;
			params["password2"] = user_data.password2;
		}
		params["errMsg"] = user_data.errMsg;
		if(window["grecaptcha"]!=null)
		{
			params["g-recaptcha-response"] = window["grecaptcha"].getResponse()
		}
		
		let response = await lib.ioCallAction
		({
			"entity": "com.sso.api.userRegister.regUser", 
			"params": params,
		});
		
		if ( response && response.error_code == 1 &&
			response.user_data != null && typeof response.user_data == "object"
		)
		{
			if (app_settings.IOPROJECT == "com.mobi")
			{
				lib.setCookie('bmc_jwt', response.jwt_string)
			}
			this.setUserProfile(response.user_data);
		}
		return response;
	}
	
	
	
	/**
	 * Регистрация Компании
	 * Возращает объект с результатом запроса, или null если ошибка backend
	 */
	async doRegisterCompany(user_data)
	{
		let params = {};
		params["sname"] = '';
		params["fname"] = user_data.myCompanyName;
		params["iin"] = user_data.myCompanyBin;
		params["pol"] = 3;
		params["email"] = user_data.email;
		params["tel"] = user_data.tel;
		params["login"] = user_data.myLogin;
		params["toggleValue"] = user_data.toggleValue;
		if (user_data.toggleValue == true) {
			params["password"] = user_data.password;
			params["password2"] = user_data.password2;
		}
		params["errMsg"] = user_data.errMsg;
		if(window["grecaptcha"]!=null)
		{
			params["g-recaptcha-response"] = window["grecaptcha"].getResponse()
		}
		
		let response = await lib.ioCallAction
		({
			"entity": "com.sso.api.userRegister.regUser", 
			"params": params,
		});
		
		if( response && response.error_code == 1 &&
			response.user_data != null && typeof response.user_data == "object")
		{
			if (app_settings.IOPROJECT == "com.mobi")
			{
				lib.setCookie('bmc_jwt', response.jwt_string)
			}
			this.setUserProfile(response.user_data);
		}
		
		return response;
		
		//return (response && typeof response.data == "object") ? response.data : null;
	}
	
	
	
	/**
	 * Восстановление пароля через api 
	 * step 1
	 */
	async doRecoveryPasswordStep1(user_data)
	{
		let params = {}
		params['user_data'] = user_data
		if(window["grecaptcha"]!=null)
		{
			params["g-recaptcha-response"] = window["grecaptcha"].getResponse()
		}
		
		let response = await lib.ioCallAction({
			"entity":"com.sso.api.user.recoveryPasswordStep1", 
			"params": params
		})
		
		if(response && response.error_code == 1)
		{
			//return true
		}
		
		return response
	}
	
	
	
	/**
	 * Восстановление пароля через api 
	 * step 2
	 */
	 async doRecoveryPasswordStep2(params)
	 {
		let response = await lib.ioCallAction({
			"entity":"com.sso.api.user.recoveryPasswordStep2", 
			"params":{
				'user_data': params['email'],
				'code': params['code'],
				'pass1': params['pass1'],
				'pass2': params['pass2']
			}
		})
		
		return response
	}
	
	
	
	/**
	 * Сохранение языка через api
	 */
	doSaveLang(lang_name)
	{
        /*
		let response = await lib.ioCallAction
		({
			"entity":"com.sso.api.user.setSettings",
			"params":
			{
				'keys': {
					'mobi_language': lang_name
				}
		   }
		});
		
		if(response && response.error_code == 1)
		{
			//lib.setCookie("lang", lang_name);
		}
        */
        lib.setCookie("lang", lang_name);
        
        /* TODO: Использовать this.isCabModulePage */
        if (this.iframe_ref != undefined && this.iframe_ref != null)
        {
            this.iframe_ref.contentWindow.changeLang(lang_name)
        }
		
		//return response;
	}
	
	
	
	isAuth() {
		return this.userProfile != null && app_settings.SESSION_DATA != null;
	}

	
	
	getSname() {
		return this.userProfile != null ? this.userProfile.sname : "";
	}

	
	
	getFname() {
		return this.userProfile != null ? this.userProfile.fname : "";
	}

	
	
	getLogin() {
		return this.userProfile != null ? this.userProfile.login : "";
	}

	
	
	getUserId() {
		return this.userProfile != null ? this.userProfile.user_id : "";
	}

	
	
	getAvatarLink() {
		return this.userProfile != null ? this.userProfile.ava : "";
	}

	
    
    
    /**
	 * Выгрузка ID выбранного типа из opt
	 */
	getOptSelectedTypeId(type_id)
	{
		return this.opt_selectedTypeId
	}
    
	
	
	/**
	 * Установка ID выбранного типа из opt
	 */
	setOptSelectedTypeId(type_id)
	{
		this.opt_selectedTypeId = type_id
	}
	
	
	
	/**
	 * Устанавливает user profile
	 */
	setUserProfile(res) {
		if (res == null) {
			this.userProfile = null;
		} else {
			this.userProfile = {
				user_id: res.id,
				login: res.login,
				login_hash: res.login_hash,
				pol: res.pol,
				sname: res.sname,
				fname: res.fname,
				lname: res.lname,
				ava: res.avatar,
			};
		}
	}
	
	
	
	/** Функции работы с модулями */
	
	findModuleByApiName(api_name)
	{
        if (api_name == undefined) return null
        if (api_name == null) return null
        if (api_name == "") return null
        
        let selectedCabModules = this.getCabinetModulesList(this.getSelectedCabPkid())
        let foundModuleApiName = selectedCabModules.find(m => m.api_name==api_name)
        
        if (foundModuleApiName != undefined) return foundModuleApiName 
        return null
	}
	
	
	/** Функции работы с кабинетом */

	/**
	 * Получение списка кабинета
	 */
	getCabinetsList()
	{
		if (!this.isAuth()) return [];
		
		let cabs = app_settings.SESSION_DATA.cabinets;
		return cabs;
	}
	
	
	
	/**
	 * Поиск кабинета по account_pkid
	 */
	getCabinetByPkid(cabinet_pkid)
	{
		if (!lib.isExists(app_settings.SESSION_DATA)) return null;
		if (!lib.isExists(app_settings.SESSION_DATA.cabinets)) return null;
		if (app_settings.SESSION_DATA.cabinets[ cabinet_pkid ] == undefined) return null;
		return app_settings.SESSION_DATA.cabinets[ cabinet_pkid ];
	}
	
	
	
	/**
	 * Поиск кабинета по account_pkid
	 */
	findCabinetByAccountPkid(account_pkid)
	{
		if (!lib.isExists(app_settings.SESSION_DATA)) return null;
		if (!lib.isExists(app_settings.SESSION_DATA.cabinets)) return null;
		let cabinets = Object.values( app_settings.SESSION_DATA.cabinets );
		let find_cabinet = cabinets.find
		(
			(cabinet) => {
				return cabinet.account_pkid == account_pkid;
			}
		);
		return find_cabinet || null;
	}
	
	
	
	/**
	 * Получение  cabinet_pkid по account_pkid
	 */
    getCabinetPkidByAccountPkid(account_pkid)
    {
        let cabinet = this.findCabinetByAccountPkid(account_pkid);
        return cabinet.cabinet_pkid || null;
    }
    
    
    
    /**
     * Получение  cabinet_number по account_pkid
     */
    getCabinetNumberByAccountPkid(account_pkid)
    {
        let cabinet = this.findCabinetByAccountPkid(account_pkid);
        return cabinet.cabinet_number || null;
    }
     
     
     
    /**
	 * Получение списка сервисов в кабинете
	 */
	getCabinetServicesList(cabinet_pkid)
	{
		if (!this.isAuth()) return [];
		
		/* Получаем список всех сервисов */
		let allServices = Object.values(app_settings.SESSION_DATA.services)
		
		/* Получаем текущий кабинет */
		let cabinet = this.getCabinetByPkid(cabinet_pkid);
		if (cabinet == null) return [];
		
		/* Фильтруем сервисы, которые есть в кабинете */
		let services_ids = Object.values( cabinet.services );
		let services = allServices.filter((service)=>{
			return services_ids.indexOf(service.pkid) != -1;
		})
		
		return services
	}

	
	
	/**
	 * Получение списка модулей в кабинете
	 */
	getCabinetModulesList(cabinet_pkid)
	{
		if (!this.isAuth()) return [];
		
		/* Получаем отфильтрованный список сервисов, которые есть в кабинете */
		let cab_services = this.getCabinetServicesList(cabinet_pkid)
		
		/* Упаковываем 'модуль пкиды' в массив*/
		let cab_modules_pkid = Object.values(cab_services).map(c=>c.module_pkid);
		
		/* Фильтруем значения */
		cab_modules_pkid = cab_modules_pkid.filter(item => item != null);
		
		/* Удаляем дубликаты */
		cab_modules_pkid = cab_modules_pkid.filter(function(item, index, self) {
			return self.indexOf(item) == index;
		})
		
		/* Получаем список модулей */
		let cab_modules = cab_modules_pkid
			.map(
				module_pkid => 
				(module_pkid != null && app_settings.SESSION_DATA.modules[module_pkid] != undefined) ?
					app_settings.SESSION_DATA.modules[module_pkid] : null
			)
		;
		
		/* Убираем пустые значения */
		cab_modules = cab_modules.filter(item => item != null);
		
		return cab_modules;
	}
	
	
	
    /**
	 *  Возвращает current_account
	 */
    getCurrentAccount()
    {
        return app_settings.current_account
    }
    
	
	
	/**
	 * Установка пкида выбранного кабинета на странице ("/change-cabinet")
	 */
	setSelectedCabPkid(cabinet_pkid)
	{
		/* Если авторизован */
		if (!this.isAuth()) return;
		
		/* Если кабинет сущесвует */
		let cabinet = this.getCabinetByPkid(cabinet_pkid);
		if (cabinet==null || cabinet==undefined)
		{
            /* Устанавливаем новый кабинет */
			this.selectedCabPkid = null;
			this.selectedCabInfo = null;
			
			/* Установить куку личного аккаунта */
			lib.setCookie("current_account", app_settings.SESSION_DATA.account_pkid);
			app_settings.current_account = app_settings.SESSION_DATA.account_pkid;
		}
		else
		{
            /* Устанавливаем новый кабинет */
			this.selectedCabPkid = cabinet_pkid;
			this.selectedCabInfo = cabinet;
			
			/* Установить куку нового аккаунта */
			lib.setCookie("current_account", this.selectedCabInfo.account_pkid);
			app_settings.current_account = this.selectedCabInfo.account_pkid;
		}
	}
	
	
	
	/**
	 * Устанавливает кабинет по pkid аккаунта
	 */
	setSelectedCabPkidByAccount(account_pkid)
	{
		/* Если авторизован */
		if (!this.isAuth()) return;
		
		/* Поиск кабинета по account_pkid */
		let find_cabinet = this.findCabinetByAccountPkid(account_pkid);
		if (find_cabinet == null)
		{
			/* Устанавливаем личный аккаунт */
			this.setSelectedCabPkid(0);
		}
		else
		{
			/* Устанавливаем кабинет */
			this.setSelectedCabPkid(find_cabinet.cabinet_pkid);
		}
	}
    
	
	
	/**
	 * Выводит лого выбранного кабинета
	 */
	getSelectedCabLogo()
	{
		if (!this.isAuth()) return null;
		if (this.selectedCabInfo == null) return null;
		return this.selectedCabInfo.cabinet_logo;
	}
	
	
	
	/**
	 * Выводит pkid выбранного кабинета
	 */
	getSelectedCabPkid()
	{
		if (!this.isAuth()) return null;
		if (this.selectedCabInfo == null) return null;
		return this.selectedCabInfo.cabinet_pkid;
	}
	
	
	
    /**
	 * Выводит название выбранного кабинета
	 */
	getSelectedCabName()
	{
		if (!this.isAuth()) return null;
		if (this.selectedCabInfo == null) return null;
		return this.selectedCabInfo.cabinet_name;
	}
    
    
    
	/**
	 * Выводит number выбранного кабинета
	 */
	getSelectedCabNumber()
	{
		if (!this.isAuth()) return null;
		if (this.selectedCabInfo == null) return null;
		return this.selectedCabInfo.cabinet_number;
	}
	
	
	
	/**
	 * Возращает выбранный аккаунт
	 */
	getSelectedAccount()
	{
		if (!this.isAuth()) return null;
		if (this.selectedCabInfo == null) return null;
		return this.selectedCabInfo.account_pkid;
	}
	
	
	
	/**
	 * Получение какой язык выбран
	 */
	getSettingsMobiLang()
	{
		//if (!this.isAuth()) return 'ru';
		// Прочитать текущий url. Взять гет парметр lang
		// Иначе прочитать из куки
        let cookie_lang = lib.getCookie('lang')
        if (cookie_lang != null) return cookie_lang;
        return 'ru';
	}
	
	
	
	/**
	 * Получение настроек 
	 */
	getSettings()
	{
		if (!this.isAuth()) return null;
		return app_settings.SESSION_DATA.settings;
	}
	
	
	
	/**
	 * Получение инфы о пользователе
	 */
	getUserInfo()
	{
		if (!this.isAuth()) return null;
		return app_settings.SESSION_DATA.user_info;
	}
	
    
    
    
    /**
	 * Выводит текущий URL с opt.devkdt.info/mobile/
	 */
    getOptRoute() {
        return app_settings.opt_route
    }
	
    
	/*
	 * Возвращает True, если полномочие есть у текущего кабинета.
	 */
	checkPermission(permission_name)
	{
		if (this.selectedCabInfo == null) return false;
		return this.checkPermissionCabinet(this.selectedCabInfo.cabinet_pkid, permission_name);
	}
	
	
	
	/**
	 * Возвращает True, если полномочие есть у кабинета.
	 */
	checkPermissionCabinet(cabinet_pkid, permission_name)
	{
		let cab = this.getCabinetByPkid(cabinet_pkid);
		if (!cab) return false;
		if (cab.permission == undefined) return false;
		if (cab.permission[permission_name] == undefined) return false;
		return true;
	}
}

let state = new Auth();

const getters = {};
const actions = {};
const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};