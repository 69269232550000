import { library } from "@fortawesome/fontawesome-svg-core";
import { faTelegram } from '@fortawesome/free-brands-svg-icons'
import {
	faComment,
	faShoppingCart,
	faGlobeAsia,
	faBriefcase,
	faMobile,
	faSignInAlt,
	faMobileAlt,
	faArrowLeft,
	faEye,
	faEyeSlash,
	faCog,
	faCircleXmark,
	faSearch
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
	faComment,
	faShoppingCart,
	faGlobeAsia,
	faBriefcase,
	faMobile,
	faSignInAlt,
	faMobileAlt,
	faArrowLeft, 
	faEye,
	faEyeSlash,
	faTelegram,
	faCog,
	faCircleXmark,
	faSearch
);

export { FontAwesomeIcon };