<template>
	<div class="container text-center py-3 pt-4">
		<modal-window title="Удаление предложения" ref="thisModal">
			<template #body>
				<span
					>Вы уверены, что хотите удалить предложение
					<strong>{{ this.tradeModel.offer.title }}</strong
					>?</span
				>
			</template>
			<template #footer_btn_cancel>
				<span>
					Нет
				</span>
			</template>
			<template #footer_btn_ok>
				<button class="btn btn-primary btn-lg flex-fill" @click="confirmModal()">
					Да
				</button>
			</template>
		</modal-window>
		<div class="col">
			<GoodsTitle
				:value="
					route.name == 'goods:offer:add' ? 'goods:offer:add' : 'goods:offer:edit'
				"
			/>
			<GoodsTypeName />
			<div
				class="col pb-4 text-center text-success fw-bold"
				v-if="this.tradeModel.offer.title != ''"
			>
				{{ this.tradeModel.offer.title }}
			</div>
			<div class="container d-grid gap-3">
				<div class="col col-md-8 col-lg-6 mx-auto">
					<!-- Photos label -->
					<div class="col mb-2 text-success">
						<label for="">Фотография товара</label>
					</div>
					<!-- Offer Photos -->
					<div class="col">
						<div class="col d-flex justify-between gap-4">
							<div class="col w-1/2 pb-3 rounded">
								<div class="mb-3">
									<img
										src="/assets/kartofel-1.jpg"
										class="w-100 img-fluid border rounded shadow-sm offer_save__image"
										alt=""
									/>
								</div>
								<div class="">
									<button class="btn btn-outline-success w-100 shadow-none">
										Свойства фото
									</button>
								</div>
							</div>
							<div class="col w-1/2 pb-3 rounded">
								<div class="mb-3">
									<img
										src="/assets/kartofel-2.jpg"
										class="w-100 img-fluid border rounded shadow-sm offer_save__image"
										alt=""
									/>
								</div>
								<div class="">
									<button class="btn btn-outline-success w-100 shadow-none">
										Свойства фото
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Add photo button -->
				<div class="col mb-3">
					<button class="btn btn-success">+ Добавить фото</button>
				</div>
				<!-- Offer Type -->
				<div class="col mb-3">
					<div
						class="col mb-2 text-success d-flex align-items-center justify-content-center"
					>
						<label>Тип</label>
						<span class="red-star"> * </span>
					</div>
					<div class="input-group">
						<select
							class="form-select text-center offer_type is_required_param"
							v-model="this.tradeModel.offer.type"
							@change="offerTitle()"
						>
							<option ref="selected" value="1">Розничное предложение</option>
							<option value="2">Оптовое предложение</option>
						</select>
					</div>
				</div>
				<!-- Offer Params -->
				<div class="col mb-3" v-if="tradeModel.goods_params_info.length != 0">
					<div class="col mb-2 text-success">
						<label for="">Параметры предложения</label>
					</div>
					<div
						class="col mb-2"
						v-for="goods_params in tradeModel.goods_params_info"
						:key="goods_params.pkid"
					>
						<div v-if="goods_params.param_type == 2">
							<div
								class="col mb-2 text-success d-flex align-items-center justify-content-center"
							>
								<label for="">{{ goods_params.meta.name.ru }}</label>
								<span v-if="goods_params.is_required == 1" class="red-star"> * </span>
							</div>
							<div class="input-group" v-if="goods_params.type == '1'">
								<select
									class="form-select text-center pe-1"
									:class="{
										is_required_param: goods_params.is_required == 1,
										param_color: goods_params.param_pkid == 1,
										param_size: goods_params.param_pkid == 7,
									}"
									@change="
										onChangeValue(
											$event,
											goods_params.type,
											goods_params.param_pkid,
											goods_params.param_type
										);
										offerTitle();
									"
									v-show="goods_params.type == '1'"
									v-bind:value="
										tradeModel.getParamsValuePkid(
											goods_params.param_pkid,
											goods_params.param_type
										)
									"
								>
									<option
										v-for="params_value in goods_params.values"
										:key="params_value.pkid"
										:value="params_value.pkid"
									>
										{{ params_value.name_ru }}
									</option>
								</select>
							</div>
							<div class="input-group" v-else>
								<input
									class="form-control w-100 text-center"
									:class="goods_params.is_required == 1 ? 'is_required_param' : ''"
									type="text"
									@change="
										onChangeValue(
											$event,
											goods_params.type,
											goods_params.param_pkid,
											goods_params.param_type
										)
									"
									v-bind:value="
										tradeModel.getParamsValueText(
											goods_params.param_pkid,
											goods_params.param_type
										)
									"
								/>
							</div>
						</div>
					</div>
				</div>
				<!-- Unit -->
				<div class="col mb-3">
					<div
						class="col mb-2 text-success d-flex align-items-center justify-content-center"
					>
						<label for="">Ед. изм.</label>
						<span class="red-star"> * </span>
					</div>
					<div class="input-group">
						<select
							class="form-select text-center pe-1 offer_unit is_required_param"
							v-model="tradeModel.offer.unit_pkid"
							@change="offerTitle()"
						>
							<option
								v-for="unit in tradeModel.units"
								:key="unit.pkid"
								:value="unit.pkid"
							>
								{{ unit.meta.short_name.ru }}
							</option>
						</select>
					</div>
				</div>
				<!-- Price per unit in tenge -->
				<div class="col mb-3">
					<div class="col mb-2 text-success">
						<label for="">Цена за ед. в тенге</label>
					</div>
					<div class="input-group">
						<input
							class="form-control w-100 text-center offer_price"
							type="text"
							id="offer_price"
							v-model="this.tradeModel.offer.price_per_unit_kzt"
							@input="setValue($event, 'price')"
							@change="offerTitle()"
						/>
					</div>
				</div>
				<!-- Min order unit count -->
				<div class="col">
					<div class="col mb-2 text-success">
						<label for="">Мин. партия для заказа</label>
					</div>
					<div class="input-group">
						<input
							class="form-control w-100 text-center offer_min_count"
							type="text"
							id="offer_min_order"
							v-model="this.tradeModel.offer.min_order_unit_count"
							@input="setValue($event, 'min_count')"
							@change="offerTitle()"
						/>
					</div>
				</div>
				<!-- bottom part -->
				<!-- errMsg -->
				<div
					class="col-12 errMsg mt-3 d-flex align-items-center p-3 rounded-3 border border-1 shadow-sm"
					v-if="errMsg"
				>
					<img
						src="/assets/info-circle-solid.svg"
						alt="info: "
						class="image-fluid me-2"
						style="width: 20px; height: 20px"
					/>
					{{ errMsg }}
				</div>
				<!-- hr -->
				<hr class="my-3" />
				<!-- Navigation Bottom Buttons -->
				<mobile-bottom-buttons
					@back="onClickBack()"
					@delete="showModal()"
					@save="goodsSaveOffer()"
					@add="goodsSaveOffer()"
					:buttons="tradeModel.isOfferAdd() ? ['back','add'] : ['back','delete','save']"
				/>
			</div>
		</div>
	</div>
</template>

<script>
async function routeInit(type, to, from, next)
{
	let goods_pkid = to.params.goods_pkid
	let offer_pkid = to.params.offer_pkid

	storeInstance.state.app.setLoadingState(true);
	storeInstance.state.trade.clearOffer();

	await storeInstance.state.trade.doLoadGoods(goods_pkid)

	if(to.name == 'goods:offer:edit')
	{
		await storeInstance.state.trade.doLoadOffer(offer_pkid);
	}

	await storeInstance.state.trade.doLoadTypes();
	await storeInstance.state.trade.doLoadParams();
	await storeInstance.state.trade.doLoadUnits();

	storeInstance.state.app.setLoadingState(false);
	next(vm => {
		vm.goods = storeInstance.state.trade.goods
	});
}

import { useRoute } from "vue-router"
export default {
	setup()
	{
		return ({
			route: useRoute()
		})
	},
	async beforeRouteEnter(to, from, next)
	{
		await routeInit("beforeRouteEnter", to, from, next);
	},
	async beforeRouteUpdate(to, from, next)
	{
		await routeInit("beforeRouteUpdate", to, from, next);
	},
	emits: ['back', 'delete', 'save'],
	data()
	{
		return {
			errMsg: "",
			new_id: 0,
			goods: {},
		}
	},
	updated()
	{
		window["onIframeClick"]()
	},
	methods:
	{
		onClickBack(){
			this.$emit('back')
			this.$router.push({
				name: this.$route.meta.backUrlName,
				params: {
					type_id: this.tradeModel.getTypeId(),
					goods_pkid: this.tradeModel.getGoodsPkid(),
				},
				query: {
					account: this.authModel.getCurrentAccount(),
					lang: this.authModel.getSettingsMobiLang(),
				},
			})
        },
		async onClickDelete(){
			this.$emit('delete')
			let response = await this.tradeModel.deleteOffer()
				if(response)
				{
					this.errMsg = 'Удалено.'
					this.onClickBack()
				}
				else
				{
					this.errMsg = 'Не удалось удалить предложение.'
				}
        },
		showModal()
		{
			this.$refs.thisModal.openModal()
		},
		confirmModal()
		{
			this.onClickDelete()
			this.$refs.thisModal.closeModal()
		},
		setValue(event, type) {
			let only_number = event.target.value.replace(/[^\d]/g, "");
			if(type == 'price')
			{
				this.tradeModel.offer.price_per_unit_kzt = only_number
			}
			else if(type == 'min_count')
			{
				this.tradeModel.offer.min_order_unit_count = only_number
			}
			this.$forceUpdate();
		},
		onChangeValue(event, type, param_pkid, param_type)
		{
			let value = event.target.value;
			if (type == 1)
			{
				this.tradeModel.setParamsValuePkid(param_pkid, value, param_type);
			}
			else if (type == 0)
			{
				this.tradeModel.setParamsValueText(param_pkid, value, param_type);
			}
		},
		validateForm() {
			let is_required_params = document.getElementsByClassName('is_required_param')
			if(is_required_params == null
				|| is_required_params == undefined
				|| is_required_params.length == 0)
			{
				return true
			}
			let empty_field = Object.values(is_required_params).find((el, index) => {
				this.new_id = index
				return el.value == ""
			})
			if(empty_field)
			{
				empty_field.focus()
				empty_field.setAttribute('id', this.new_id)
				window["scrollToElement"](empty_field.id)
				return false
			}
			return true
		},
		async goodsSaveOffer()
		{
			this.tradeModel.isOfferAdd() ? this.$emit('add') : this.$emit('save')
			this.errMsg = ""
			if(this.validateForm())
			{

				let response = await this.tradeModel.doOfferSave()
				if (response && response.error_code == 1)
				{
					this.$router.push({
						name: 'goods:offers:list',
						params: {
							type_id: this.tradeModel.getTypeId(),
							goods_pkid: this.tradeModel.getGoodsPkid(),
						},
						query: {
							account: this.authModel.getCurrentAccount(),
							lang: this.authModel.getSettingsMobiLang(),
						},
					})
				}
				else
				{
					this.errMsg = "Ошибка."
				}
			}
			else
			{
				this.errMsg = "Заполните обязательные поля."
			}
		},
		offerTitle()
		{
			let type = document.getElementsByClassName('offer_type')[0].value
			let size = undefined
			let color = undefined
			if(this.tradeModel.goods_params_info.length != 0)
			{
				let sizes = document.getElementsByClassName('param_size')
				let colors = document.getElementsByClassName('param_color')
				if(sizes && sizes.length != 0)
				{
					size = document.getElementsByClassName('param_size')[0].value
				}
				if(colors && colors.length != 0)
				{
					color = document.getElementsByClassName('param_color')[0].value
				}
			}
			let price = document.getElementsByClassName('offer_price')[0].value
			let unit = document.getElementsByClassName('offer_unit')[0].value
			let min_count = document.getElementsByClassName('offer_min_count')[0].value

			let offer_title = ''

			let all_units = this.tradeModel.units
			all_units.forEach(au => {
				if(au.pkid == unit)
				{
					unit = au.meta.short_name.ru
				}
			})

			if(type != '')
			{
				type = (type == '1') ? 'розн' : 'опт'
			}

			if(price != '')
			{
				price += ' тенге'

				if(unit != '' && min_count != '')
				{
					price += ' за ' + min_count + ' ' + unit
				}
			}


			let fields = [type, price]

			if(size != undefined && color != undefined)
			{
				let selectable_params = [size, color]
				let goods_params = this.tradeModel.goods_params_info

				selectable_params.forEach(sp => {
					goods_params.forEach(gp => {
						gp.values.forEach(gpv => {
							if(sp == gpv.pkid)
							{
								sp = gpv.meta.name.ru
								if(gpv.param_pkid == 1) color = sp
								if(gpv.param_pkid == 7) size = sp
							}
						})
					})
				})
				fields.splice(1, 0, size)
				fields.splice(2, 0, color)
			}

			fields.forEach(el => {
				if(el != '')
				{
					if(el == fields[0]) offer_title += el
					if(fields[0] != '' && el != fields[0]) offer_title += ', ' + el
				}
			})

			this.tradeModel.offer.title = offer_title
			return offer_title
		}
	},
	computed: {
		authModel() {
			return this.$store.state.auth;
		},
		tradeModel() {
			return this.$store.state.trade;
		},
	},
};
</script>

<style lang="scss" scoped>
.offer_save {
	&__image {
		height: 100px;
	}
}

.red-star {
	color: red;
}
</style>
