<template>
	<div
		v-if="showModal"
		class="modal fade"
		tabindex="-1"
		aria-labelledby=""
		aria-hidden="true"
		ref="modalEl"
		:style="showModal ? { display: 'block', opacity: '1' } : { display: 'none' }"
	>
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content" id="modal_window">
				<div class="modal-header p-2 px-3 d-flex justify-content-center">
					<h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
				</div>
				<div class="modal-body p-4 px-3">
					<slot name="body" />
				</div>
				<slot name="footer_errMsg"></slot>
				<div class="col p-3 d-flex  gap-3 pt-0 d-flex justify-content-between">
					<button
						type="button"
						@click="closeModal()"
						class="btn btn-secondary btn-lg flex-fill"
						data-bs-dismiss="modal"
					>
						<slot name="footer_btn_cancel"></slot>
					</button>
					<slot name="footer_btn_ok"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "modalWindow",
	data() {
		return {
			showModal: false,
		};
	},
	props: {
		title: String,
	},
	methods: {
		closeModal() {
			window["scrollToTop"]()
			storeInstance.state.app.setLoadingStateWithoutSpinner(false);
			storeInstance.state.app.setLoadingState(false);
			this.showModal = false;
		},
		openModal() {
			window["scrollToElement"]('modal_window')
			storeInstance.state.app.setLoadingStateWithoutSpinner(true);
			storeInstance.state.app.setLoadingState(true);
			this.showModal = true;
		},
	},
};
</script>
<style></style>
