<template>
	<div class="container text-center py-3 pt-4">
		<modal-window title="Удаление предложения" ref="thisModal">
			<template #body>
				<span v-if="lang == 'ru'"
					>Вы уверены, что хотите удалить товар
					<strong>{{ tradeModel.goods.meta.name.ru }}</strong>?
				</span>
			</template>
			<template #footer_btn_cancel>
				<span>
					Нет
				</span>
			</template>
			<template #footer_btn_ok>
				<button class="btn btn-primary btn-lg flex-fill" @click="confirmModal()">
					Да
				</button>
			</template>
		</modal-window>
		<div class="col">
			<GoodsTitle 
				:value="tradeModel.isAdd() 
				? 'goods:add' 
				: 'goods:edit:info'" 
			/>
			<GoodsTypeName />
			<!-- top part v1 -->
			<div class="container d-grid gap-3">
				<!-- Photo -->
				<div class="col col-md-8 col-lg-6 mx-auto">
					<div class="col mb-2 text-success">
						<label for="">Фотография товара</label>
					</div>
					<!-- <div class="col"> -->
						<div 
							class="row d-flex justify-between"
						>
							<div 
								class="col-6 flex-fill pb-3 align-items-center 
								rounded d-flex justify-content-center flex-column "
								v-for="(image, key) in tradeModel.goods.photos"
								:key="key"
							>
								<div 
									class="col mb-3 d-flex flex-column" 
								>
									<img 
										class="img-fluid border 
										rounded shadow-sm goods_save_info__image" 
										:ref="'image' +parseInt(key)" 
										:src="image.orig_url"
									/> 
								</div>
								<div class="">
									<button 
										v-if="image"
										class="btn btn-outline-success w-100 shadow-none"
									>
										Свойства фото
									</button>
								</div>
							</div>
						</div>
					<!-- </div> -->
				</div>
				<!-- Set Photo Button -->
				<div class="col mb-3">
					<!-- <button class="btn btn-success">+ Добавить фото</button> -->
					<div class="mb-3 input-group d-flex 
						justify-content-center custom-file-button">
						<label 
							class="position-absolute input-group-text 
							btn btn-success rounded" 
							for="inputGroupFile"
							style="z-index: 0;"
						>
							+ Добавить фото
						</label>
						<input 
							type="file" 
							multiple
							ref="img_upload_btn"
							class="form-control d-none" 
							id="inputGroupFile"
							name="file"
							@change="imgUpload($event)"
						/>
					</div>
				</div>
				<!-- Set Lang Buttons -->
				<div class="row my-3">
					<div class="col d-flex justify-content-end">
						<button
							@click="chooseProductNameLang('kz')"
							class="btn btn-sm px-3 btn-outline-primary shadow-none"
							:class="lang == 'kz' ? 'active shadow-sm' : ''"
						>
							KZ
						</button>
					</div>
					<div class="col d-flex justify-content-center">
						<button
							@click="chooseProductNameLang('ru')"
							class="btn btn-sm px-3 btn-outline-primary shadow-none"
							:class="lang == 'ru' ? 'active shadow-sm' : ''"
						>
							RU
						</button>
					</div>
					<div class="col d-flex justify-content-start">
						<button
							@click="chooseProductNameLang('en')"
							class="btn btn-sm px-3 btn-outline-primary shadow-none"
							:class="lang == 'en' ? 'active shadow-sm' : ''"
						>
							EN
						</button>
					</div>
				</div>
				<!-- Goods Name -->
				<div class="col mb-3">
					<div class="col mb-2 text-success">
						<label for="">Название товара {{ lang_full_str }}</label>
					</div>
					<div class="input-group">
						<input
							v-show="lang == 'kz'"
							class="form-control w-100"
							type="text"
							v-model="tradeModel.goods.meta.name.kz"
						/>
						<input
							v-show="lang == 'ru'"
							class="form-control w-100"
							type="text"
							v-model="tradeModel.goods.meta.name.ru"
						/>
						<input
							v-show="lang == 'en'"
							class="form-control w-100"
							type="text"
							v-model="tradeModel.goods.meta.name.en"
						/>
					</div>
				</div>
				<!-- Goods Description -->
				<div class="col mb-3">
					<div class="col mb-2 text-success">
						<label for="">Описание товара {{ lang_full_str }}</label>
					</div>
					<div class="input-group">
						<textarea
							v-show="lang == 'kz'"
							class="form-control"
							v-model="tradeModel.goods.meta.description.kz"
						/>
						<textarea
							v-show="lang == 'ru'"
							class="form-control"
							v-model="tradeModel.goods.meta.description.ru"
						/>
						<textarea
							v-show="lang == 'en'"
							class="form-control"
							v-model="tradeModel.goods.meta.description.en"
						/>
					</div>
				</div>
				<!-- Goods Categories -->
				<div
					class="col"
					:class="tradeModel.goods.categories.length != 0
							? 'mb-3'
							: ''"
				>
					<div 
						class="col text-success"
						:class="tradeModel.goods.categories.length != 0
							? 'mb-2'
							: ''"
					>
						<label for="">Категории товара</label>
					</div>
					<ul class="list-group" v-if="tradeModel.goods.categories.length != 0">
						<li
							class="list-group-item d-flex"
							v-for="(cat_pkid, i) in tradeModel.goods.categories"
							:key="i"
						>
							<div class="col text-start d-flex align-items-center">
								<span class="text-muted fw-bold">
									<div class="mb-0">
										<p class="mb-0">
											{{ this.tradeModel.getCategoriesByPkid(cat_pkid).category_name }}
										</p>
									</div>
								</span>
							</div>
							<div class="col-1 p-0 d-flex justify-content-end">
								<button
									@click="removeCatItem(i, cat_pkid)"
									role="button"
									class="btn btn-sm btn-outline-secondary border border-1 shadow-none text-decoration-none"
								>
									<font-awesome-icon icon="circle-xmark" />
								</button>
							</div>
						</li>
					</ul>
				</div>
				<!-- Set Goods Category Button -->
				<div class="col mb-4">
					<router-link
						id="goods_categories"
						v-if="this.tradeModel.isAdd()"
						:to="{
							name: 'goods:add:categories',
							params: {
								type_id: this.tradeModel.getTypeId(),
							},
							query: {
								account: this.authModel.getCurrentAccount(),
								lang: this.$route.query.lang,
							},
						}"
						class="btn btn-success"
						role="button"
					>
						Настроить категории
					</router-link>
					<router-link
						v-else
						id="goods_categories"
						:to="{
							name: 'goods:edit:categories',
							params: {
								goods_pkid: this.tradeModel.getGoodsPkid(),
								type_id: this.tradeModel.getTypeId(),
							},
							query: {
								account: this.authModel.getCurrentAccount(),
								lang: this.$route.query.lang,
							},
						}"
						class="btn btn-success"
						role="button"
					>
						Настроить категории
					</router-link>
				</div>
			</div>
			<!-- Goods Params -->
			<div class="col mb-4">
				<div class="container d-grid gap-3">
					<div class="col mb-2">
						<div class="col mb-2 text-success">
							<label for="">Производитель</label>
						</div>
						<div class="input-group">
							<input
								class="form-control w-100 text-center"
								type="text"
								v-model="goods.manufacturer"
							/>
						</div>
					</div>
					<div class="col mb-2">
						<div class="col mb-2 text-success">
							<label for="">Артикул</label>
						</div>
						<div class="input-group">
							<input
								class="form-control w-100 text-center"
								type="text"
								v-model="goods.vendor_code"
							/>
						</div>
					</div>

					<template
						class="col mb-2"
						v-for="goods_params in tradeModel.goods_params_info"
						:key="goods_params.pkid"
					>
						<div v-show="goods_params.param_type == 1">
							<div class="col mb-2 text-success">
								<label for="">{{ goods_params.meta.name.ru }}</label>
							</div>

							<div class="input-group" v-if="goods_params.type == '1'">
								<select
									class="form-select text-center pe-1"
									@change="
										onChangeValue($event, goods_params.type, goods_params.param_pkid, goods_params.param_type)
									"
									v-show="goods_params.type == '1'"
									v-bind:value="tradeModel.getParamsValuePkid(goods_params.param_pkid, goods_params.param_type)"
								>
									<option
										v-for="params_value in goods_params.values"
										:key="params_value.pkid"
										:value="params_value.pkid"
									>
										{{ params_value.name_ru }}
									</option>
								</select>
							</div>
							<div class="input-group" v-else>
								<input
									class="form-control w-100 text-center"
									type="text"
									@change="
										onChangeValue($event, goods_params.type, goods_params.param_pkid, goods_params.param_type)
									"
									v-bind:value="tradeModel.getParamsValueText(goods_params.param_pkid, goods_params.param_type)"
								/>
							</div>
						</div>
					</template>
				</div>
			</div>
			<!-- bottom part -->
			<!-- errMsg -->
			<div
				class="col-12 errMsg mt-3 d-flex align-items-center p-3 rounded-3 border border-1 shadow-sm"
				v-if="errMsg"
			>
				<img
					src="/assets/info-circle-solid.svg"
					alt="info: "
					class="image-fluid me-2"
					style="width: 20px; height: 20px"
				/>
				{{ errMsg }}
			</div>
			<!-- hr -->
			<hr class="my-3" />
			<!-- Navigation Bottom Buttons -->
			<mobile-bottom-buttons
				@back="onClickBack()"
				@delete="showModal()"
				@save="goodsSaveInfo()"
				@add="goodsSaveInfo()"
				:buttons="tradeModel.isAdd() ? ['back','add'] : ['back','delete','save']"
			/>
		</div>
	</div>
</template>

<script>

async function routeInit(type, to, from, next)
{
	storeInstance.state.app.setLoadingState(true);
	if (from.name != "goods:add:categories" && from.name != "goods:edit:categories")
	{
		storeInstance.state.trade.clear();
	}
	
	if (from.name == "goods:add:categories" && from.name == "goods:edit:categories")
	{
		storeInstance.state.trade.getGoods();
	}
	
	if (to.name == "goods:add")
	{
		let type_id = to.params.type_id;
		storeInstance.state.trade.setTypeId(type_id);
	}
	else if (to.name == "goods:edit:info")
	{
		let goods_pkid = to.params.goods_pkid
		if (storeInstance.state.trade.getGoodsPkid() != goods_pkid)
		{
			await storeInstance.state.trade.doLoadGoods(goods_pkid);
		}
	}
	
	if (storeInstance.state.trade.categories == null)
	{
		await storeInstance.state.trade.doLoadCategories();
	}
	
	// component.data.is_goods_add = false;
	
	await storeInstance.state.trade.doLoadTypes();
	await storeInstance.state.trade.doLoadParams();
	
	storeInstance.state.app.setLoadingState(false);
	next(vm => {
		vm.goods = storeInstance.state.trade.goods
    	vm.prevRoute = from.name
	});
}

import GoodsTitle from "trade/pages/mobile/goods/GoodsTitle.vue"
import GoodsTypeName from "trade/pages/mobile/goods/GoodsTypeName.vue"
export default {
	emits: ['back', 'delete', 'save', 'add'],
	components: {
		GoodsTitle, GoodsTypeName
	},
	data() 
	{
		return {
			goods: {},
			is_goods_add: false,
			lang: "",
			lang_full_str: "",
			errMsg: "",
			editing: false,
			prevRoute: null,
			images: [],
		}
	},
	beforeRouteEnter(to, from, next)
	{
		routeInit("beforeRouteEnter", to, from, next);
	},
	beforeRouteUpdate(to, from, next)
	{
		routeInit("beforeRouteUpdate", to, from, next);
	},
	mounted() {
		this.chooseProductNameLang(this.$route.query.lang);
	},
	updated()
	{
		window["onIframeClick"]()
	},
	methods: {
		launchImgUpload()
		{
			this.$refs.img_upload_btn.click()
		},
		async imgUpload(e)
		{
			let selectedFiles = e.target.files;
			let response_photos = await this.tradeModel.upload_photo(selectedFiles);
			if(response_photos != null)
			{
				this.tradeModel.setGoodsPhotos(response_photos)
			}
		},
		onClickBack(){
			this.$emit('back')
			if(this.prevRoute == 'goods:add:select_type' || this.tradeModel.isAdd())
			{
				this.$router.push({
					name: 'goods:add:select_type',
					query: {
						account: this.authModel.getCurrentAccount(),
						lang: this.$route.query.lang,
					},
				})
			}
			else
			{
				this.$router.push({
					name: 'goods:edit',
					params: {
						goods_pkid: this.tradeModel.getGoodsPkid(),	
					},
					query: {
						account: this.authModel.getCurrentAccount(),
						lang: this.$route.query.lang,
					},
				})
			}
        },
		async onClickDelete(){
			this.$emit('delete')
			let response = await this.tradeModel.deleteGoods()
			if(response)
			{
				this.errMsg = 'Удалено.'
				this.onClickBack()
			}
			else
			{
				this.errMsg = 'Не удалось удалить товар.'
			}
			
        },
		showModal()
		{
			this.$refs.thisModal.openModal()
		},
		confirmModal()
		{
			this.onClickDelete()
			this.$refs.thisModal.closeModal()
		},
		chooseProductNameLang(lang) {
			this.editing = true;
			this.lang = lang;
			if (lang == "kz") this.lang_full_str = "на казахском";
			else if (lang == "ru") this.lang_full_str = "на русском";
			else if (lang == "en") this.lang_full_str = "на английском";
		},
		removeCatItem(index, pkid) {
			this.tradeModel.unsetGoodsCategory(pkid);
		},
		/**
		 * Изменилось значение параметра
		 **/
		onChangeValue(event, type, param_pkid, param_type)
		{
			let value = event.target.value;
			if (type == 1)
			{
				this.tradeModel.setParamsValuePkid(param_pkid, value, param_type);
			}
			else if (type == 0)
			{
				this.tradeModel.setParamsValueText(param_pkid, value,  param_type);
			}
		},
		validateForm() {
			let goods_name = this.tradeModel.goods.meta.name[this.lang];
			let goods_description =
				this.tradeModel.goods.meta.description[this.lang];
			if (
				Object.keys(goods_name).length === 0 ||
				Object.keys(goods_description).length === 0
			) 
			{
				return false;
			} 
			else return true;
		},
		async goodsSaveInfo() {
			this.tradeModel.isAdd() ? this.$emit('add') : this.$emit('save')
			let validation_result = this.validateForm();
			if (validation_result == true) {
				this.tradeModel.goods["cabinet_number"] =
					this.authModel.getSelectedCabNumber();
				this.tradeModel.goods["type_id"] = this.tradeModel.getTypeId();

				let response = await this.tradeModel.doGoodsSaveInfo()
				
				if (response.error_code == 1) 
				{
					if(this.tradeModel.isAdd())
					{
						this.tradeModel.goods["pkid"] = response.goods_pkid
						this.tradeModel.goods_pkid = response.goods_pkid
					}

					this.onClickBack()
				} 
				else 
				{
					this.errMsg = response.error_str;
				}
			} 
			else 
			{
				this.errMsg = "Заполните пустые поля";
			}
		},
	},
	computed: {
		authModel() 
		{
			return this.$store.state.auth;
		},
		tradeModel() 
		{
			return this.$store.state.trade;
		},
	},
};
</script>

<style>
.errMsg {
	color: #963;
	background-color: #f7efcb;
	border-color: #e7d5b2;
	border: 1px solid #fbeed5;
	text-align: start;
	font-weight: bold;
}
.custom-file-button input[type=file] {
  margin-left: -2px !important;
}

.custom-file-button input[type=file]::-webkit-file-upload-button {
  display: none;
}

.custom-file-button input[type=file]::file-selector-button {
  display: none;
}

.custom-file-button:hover label {
  background-color: #dde0e3;
  cursor: pointer;
}
</style>

<style lang="scss" scoped>
.goods_save_info {
	&__image {
		height: 100px;
	}
}
</style>
