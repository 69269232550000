<template>
	<div class="container text-center px-0 py-3 pt-4">
		<modal-window title="Перейти на страницу" ref="thisModal">
			<template #body>
				<div class="col">
						<div class="input-group">
							<input
								class="form-control"
								type="text"
								placeholder="Введите номер страницы"
								v-model="customPage"
								@input="setValue($event)"
								style="z-index: 0"
							/>
						</div>
					</div>
			</template>
			<template #footer_errMsg>
				<!-- errMsg -->
				<div
					class="col errMsg mt-0 mb-4 m-3 d-flex align-items-center p-3 rounded-3 border border-1 shadow-sm"
					v-if="modal_errMsg"
				>
					<img
						src="/assets/info-circle-solid.svg"
						alt="info: "
						class="image-fluid me-2"
						style="width: 20px; height: 20px"
					/>
					{{ modal_errMsg }}
				</div>
			</template>
			<template #footer_btn_cancel>
				<span>
					Отмена
				</span>
			</template>
			<template #footer_btn_ok>
				<button class="btn btn-primary btn-lg flex-fill" @click="goToPage(customPage)">
					Перейти
				</button>
			</template>
		</modal-window>
		<div class="col">
			<GoodsTitle value="goods:list" />
			<div class="col">
				<div class="container d-grid px-0 gap-3">
					<div class="col pb-5 px-3">
						<div class="input-group" v-if="page_loaded==true">
							<input
								class="form-control"
								type="text"
								placeholder="Название товара, код товара"
								v-model="searchText"
								style="z-index: 0"
							/>
							<button 
								class="btn btn-outline-secondary" 
								type="button"
								@click="search()"
								>
								<font-awesome-icon icon="search" class="" />
							</button>
						</div>
					</div>
					<div 
						class="col d-flex flex-wrap mb-3" 
					>
						<div
							class="col text-muted text-center"
							v-if="msgNotFoundText != '' && searchText != ''"
						>
							{{msgNotFoundText}}
						</div>
						<div 
							v-else
							class="col-6 d-flex justify-content-center"
							v-for="row in this.all_goods"
							:key="row.id"
						>
							<div class="col-10 pb-3 rounded">
								<router-link
									:to="{
										name: 'goods:edit',
										params: 
										{
											goods_pkid: row.pkid	
										},
										query: 
										{
											account: this.authModel.getCurrentAccount(),
											lang: this.$route.query.lang,
										}
									}"
									class="text-decoration-none text-dark"
									role="button"
								>
									<div class="mb-3 d-flex 
									justify-content-center align-items-center">
										<img
											src="/assets/carrots.jpg"
											class="img-fluid border rounded 
											shadow-sm goods_list__image"
											:class="image_loaded ? '' : 'd-none'"
											alt=""
											@load="imgOnload()"
										/>
										<div v-if="!image_loaded" class="loading-wheel"></div>
									</div>
									<div class="">
										<p class="text-center mb-0">{{ row.name_ru }}</p>
										<p class="text-center mb-0">{{ row.vendor_code }}</p>
									</div>
								</router-link>
							</div>
						</div>
					</div>
					<div class="col text-center text-muted" v-if="page_loaded==true">
						<span>показано с {{this.start}} по {{this.end}} из {{this.total}}</span>	
					</div>
					<div 
						class="row gap-3 d-flex flex-wrap mx-auto pb-4"
						v-if="page_loaded==true"
					>
						<div class="col">
							<button 
								class="btn btn-outline-secondary"
								@click="currentPage--; setPage(currentPage)"
								:style="this.currentPage == 1 
										|| this.msgNotFoundText != ''
										? {visibility: 'hidden'} : ''"
							>
								Назад
							</button>
						</div>
						<div class="col">
							<div class="col d-flex flex-wrap justify-content-center">
								<button
									v-for="(pageNumber, i) in totalPages"
									:key="i"
									class="btn"
									:class="currentPage == pageNumber 
									? 'btn-primary' : 'btn-outline-primary border-0 d-none'"
									@click="showModal()"
								>
									{{ pageNumber }}
								</button>
							</div>
						</div>
						<div class="col">
							<button 
								class="btn btn-secondary"
								@click="currentPage++; setPage(currentPage)"
								:style="this.currentPage == this.totalPages 
										|| this.msgNotFoundText != ''
										? {visibility: 'hidden'} : ''"
							>
								Вперед
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
async function routeInit(type, to, from, next)
{
	storeInstance.state.app.setLoadingState(true);
	await storeInstance.state.trade.doLoadAllGoods();
	storeInstance.state.app.setLoadingState(false);
	next()
}
import GoodsTitle from "trade/pages/mobile/goods/GoodsTitle.vue"
export default {
	components: {
		GoodsTitle,
	},
	data() {
		return {
			search_flag: false,
			msgNotFoundText: "",
			modal_errMsg: "",
			all_goods: [],
			start: 0,
			end: 0,
			total: 0,
			currentPage: this.$store.state.trade.current_page,
			itemsPerPage: this.$store.state.trade.page_limit,
			totalPages: this.$store.state.trade.total_pages,
			searchText: this.$store.state.trade.search_text,
			page_loaded: false,
			customPage: "",
			image_loaded: false,
		}
	},
	async mounted()
	{
		await this.refreshItems()
	},
	updated()
	{
		window["onIframeClick"]()
	},
	beforeRouteEnter(to, from, next)
	{
		routeInit("beforeRouteEnter", to, from, next);
	},
	beforeRouteUpdate(to, from, next)
	{
		routeInit("beforeRouteEnter", to, from, next);
	},
	computed: {
		authModel() 
		{
			return this.$store.state.auth
		},
		tradeModel()
		{
			return this.$store.state.trade
		}
	},
	methods: 
	{
		imgOnload()
		{
			this.image_loaded = true
			window["onIframeClick"]()
		},
		search()
		{
			this.search_flag = true
			this.refreshItems()
		},
		setValue(event) {
			let only_number = event.target.value.replace(/[^\d]/g, "");
			this.customPage = only_number
			this.$forceUpdate();
		},
		showModal()
		{
			this.modal_errMsg = ""
			this.customPage = ""
			this.$refs.thisModal.openModal()
		},
		goToPage()
		{
			if(this.customPage > this.totalPages 
				|| this.customPage <= 0)
			{
				this.modal_errMsg = "Не найдено"
			}
			else this.setPage(this.customPage) 
		},
		setPage(pageNumber) 
		{
			this.$refs.thisModal.closeModal()
			this.currentPage = pageNumber;
			this.refreshItems()
		},
		async refreshItems()
		{
			storeInstance.state.app.setLoadingState(true);
			this.page_loaded = false
			
			this.tradeModel.current_page = this.search_flag == true ? 0 : this.currentPage
			this.tradeModel.search_text = this.searchText
			this.msgNotFoundText = ''
			this.all_goods = []
			
			let response = await this.tradeModel.doLoadAllGoods()
			
			if(response && response.error_code == 1)
			{
				this.all_goods = this.tradeModel.all_goods
				this.totalPages = this.tradeModel.total_pages
				this.start = response.start + 1
				this.total = response.total
				if(this.search_flag) 
				{
					this.currentPage = this.tradeModel.current_page
				}
			}
			else
			{
				this.msgNotFoundText = 'Повторите позже'
			}

			this.search_flag = false
			this.paginate()
			storeInstance.state.app.setLoadingState(false);
			this.page_loaded = true
		},
		paginate() 
		{
			if (this.tradeModel.all_goods == null) return;
			if (this.tradeModel.all_goods == undefined) return;
			if (this.tradeModel.all_goods.length == 0) 
			{
				this.msgNotFoundText = 'Не найдено'
			}
			if (this.currentPage == 0) this.currentPage = 1
			if (this.currentPage >= this.totalPages) 
			{
				this.currentPage = this.totalPages;
			}
			
			let index = this.currentPage * this.itemsPerPage - this.itemsPerPage;

			this.end = index + this.itemsPerPage
			if(this.end >= this.total) this.end = this.total
			
			return this.all_goods.slice(index, index + this.itemsPerPage);
		},
	},
};
</script>

<style lang="scss" scoped>
.goods_list{
	&__image{
		height: 100px;
	}
}
</style>
