<template>
	<div class="container mt-5 text-center">
		<div class="col">
			<div class="col d-grid gap-2">
				<div class="col" v-for="(btnName, i) in btnTitles" :key="i">
					<router-link 
						v-if="i==2" 
						class="btn btn-outline-primary w-100" 
						:to="{
							name: 'goods:add:select_type',
							query: {
								account: this.$route.query.account,
								lang: this.$route.query.lang
							}
						}"
					>
						{{btnName}}
					</router-link>
					<router-link 
						v-else-if="i==3" 
						class="btn btn-outline-primary w-100" 
						:to="{
							name: 'goods:list',
							query: {
								account: this.$route.query.account,
								lang: this.$route.query.lang
							}
						}"
					>
						{{btnName}}
					</router-link>
					<button
						v-else-if="i==7" 
						@click="vibrate()"
						class="btn btn-outline-primary w-100" 
					>
						{{btnName}}
					</button>
					<button 
						v-else-if="i==8" 
						@click="callQRScanner()"
						class="btn btn-outline-primary w-100" 
					>
						{{btnName}}
					</button>
					<router-link 
						v-else 
						class="btn btn-outline-primary w-100" 
						:to="{
							name: 'home:inDev',
							query: 
							{
								account: this.$route.query.account,
								lang: this.$route.query.lang
							}
						}"
					>
						{{btnName}}
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() 
	{
		return {
			btnTitles: [
				'Создать заказ', 'Список заказов', 'Добавить товар или услугу', 
				'Список товаров', 'Список оплат', 'Список доставки', 'Отчет',
				'Проверка вибрации', 'Проверка камеры'
			]
		}
	},
	mounted()
	{
		this.tradeModel.current_account = this.$route.query.account
	},
	methods: {
		vibrate()
		{
			lib.mobile.vibrateIframe()
		},
		callQRScanner()
		{
			lib.mobile.callQRScannerIframe({
				callback: function(decodeText){
					QRCodeDecode(decodeText);
				},
				autoCloseCamera: 1,
				autoResumeCamera: 0,
				sound: 0,
				vibration: 0,
			})
		}
	},
	computed: 
	{
		authModel() 
		{
			return this.$store.state.auth
		},
		tradeModel() 
		{
			return this.$store.state.trade
		}
	},
}
</script>

<style>

</style>