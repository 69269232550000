<template>
	<div class="row mx-auto py-4">
		<div class="d-flex p-0 text-muted">
			<div class="col"></div>
			<div class="col-10 text-center">
				<span class="fw-bold">
					<div class="mb-0">
                        <p class="mb-0">
                            {{ this.title }}
                        </p>
					</div>
				</span>
			</div>
			<div class="col"></div>
		</div>
	</div>
	<div v-if="this.is_goods_add_step == true" class="col pb-4 text-muted fw-bold">{{ this.step }}</div>
</template>

<script>
export default {
	props: 
	{
		value: String
	},
	data() 
	{
		return {
			title: '',
			is_goods_add_step: false,
			step: '',
		}	
	},
	mounted() 
	{
		if(this.value != null)
		{
			if(this.value == 'goods:list')
			{
				this.title = 'Список товаров'
			}
			else if(this.value == 'goods:edit:categories')
			{
				this.title = 'Категории'
			}
			else if(this.value == 'goods:add:select_type')
			{
				this.title = 'Добавить товар или услугу'
				this.is_goods_add_step = true
				this.step = 'Выберите тип'
			}
			else if(this.value == 'goods:add')
			{
				this.title = 'Добавить товар или услугу'
				this.is_goods_add_step = true
				this.step = 'Шаг 1'
			}
			else if(this.value == 'goods:edit:info')
			{
				this.title = 'Редактировать товар или услугу'
				this.is_goods_add_step = true
				this.step = 'Шаг 1'
			}
			else if(this.value == 'goods:offers:list')
			{
				this.title = 'Список предложений товара'
			}
			else if(this.value == 'goods:offer:add')
			{
				this.title = 'Добавить предложение товара'
				this.is_goods_add_step = true
				this.step = 'Шаг 2'
			}
			else if(this.value == 'goods:offer:edit')
			{
				this.title = 'Редактировать предложение товара'
			}
			else if(this.value == 'goods:showItem')
			{
				this.title = 'Информация о товаре'
			}
		}
	},
};
</script>

<style></style>
