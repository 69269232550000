<template>
	<div class="col mx-3 inDevPage py-3">
		<div class="col">
			<div class="card border-0 align-items-center">
				<div class="card-body py-5 text-center">
					<p class="text-center mb-5">
						<img
							src="//cdn.devkdt.info/modules/images/cabinet20/bmcsales-logo-gray.png"
							style="height: 200px"
							alt="bmc-logo"
						/>
					</p>
					<p>На данный момент эта страница еще не готова.</p>
					<p>Пожалуйста, посетите данную страницу позже.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style>
.inDevPage {
	font-size: 14px;
	color: #657483;
}
</style>
