<template>
  <div class="col pb-4 fw-bold text-success">
	<label for="">Тип товара: {{ this.goods_typeName }}</label>
</div>
</template>

<script>
import { onBeforeMount } from '@vue/runtime-core';
import { onBeforeRouteUpdate } from 'vue-router';
export default {
	data()
	{
		return {
			goods_typeName: "",
		}
	},
	mounted()
	{
		this.getTypeName()
	},
	methods: 
	{
		getTypeName()
		{
			let type_id = this.tradeModel.getTypeId()
			let all_types = this.tradeModel.getTypes()
			let goods_type = all_types.find(el => {
				return el.pkid == type_id
			})
			this.goods_typeName = goods_type.name_ru
		},
	},
	computed: {
		authModel() {
			return this.$store.state.auth;
		},
		tradeModel() {
			return this.$store.state.trade;
		},
	},
}
</script>

<style>

</style>