<template>
	<div class="row">
		<div 
			class="col d-flex"
			v-for="(button, id) in orig_buttons"
			:key="id"
		>
			<button
				@click="$emit(button.code_name)"
				role="button"
				class="btn d-flex flex-fill align-items-center justify-content-center"
				:class="button.style"
				:data-code-name="button.code_name"
			>
				<span> {{ button.name_ru }} </span>
			</button>
		</div>
	</div>
</template>

<script>
export default {
	props: 
	{
		buttons: Array,
	},
	setup(props)
	{
		let orig_buttons = props.buttons.map(el => {
			el = {
				code_name: el,
				name_ru: "",
				style: "",
			}
			if(el.code_name == 'save') 
			{
				el.name_ru = 'Сохранить'
				el.style = 'btn-success'
			}
			else if(el.code_name == 'add') 
			{
				el.name_ru = 'Добавить'
				el.style = 'btn-success'
			}
			else if(el.code_name == 'delete') 
			{
				el.name_ru = 'Удалить'
				el.style = 'btn-danger'
			}
			else if(el.code_name == 'back') 
			{
				el.name_ru = 'Назад'
				el.style = 'btn-outline-secondary'
			}
			return el 
		})
		return ({
			orig_buttons
		})
	},
	computed: 
	{
		authModel() 
		{
			return this.$store.state.auth;
		},
		tradeModel() 
		{
			return this.$store.state.trade;
		},
	},
};
</script>

<style></style>
