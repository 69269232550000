<template>
	<div class="container text-center pt-4">
		<div class="col">
			<GoodsTitle value="goods:add:select_type" />
			<div class="col d-grid gap-2">
				<div class="col" v-for="(btnName, i) in tradeModel.getTypes()" :key="i">
					<router-link 
						class="btn btn-outline-primary w-100" 
						:to="{
							name: 'goods:add', 
							params: 
							{
								type_id: btnName.pkid
							},
							query: 
							{
								account: this.authModel.getCurrentAccount(),
								lang: this.$route.query.lang,
							}
						}"
					>
						{{ btnName.meta.name.ru }}
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
async function routeInit(type, to, from, next)
{
	storeInstance.state.app.setLoadingState(true);
	await storeInstance.state.trade.doLoadTypes();
	storeInstance.state.app.setLoadingState(false);
	next();
}
import GoodsTitle from "trade/pages/mobile/goods/GoodsTitle.vue"
export default {
	//inheritAttrs: false,
	components: 
	{
		GoodsTitle, 
	},
	async beforeRouteEnter(to, from, next)
	{
		await routeInit("beforeRouteEnter", to, from, next);
	},
	async beforeRouteUpdate(to, from, next)
	{
		await routeInit("beforeRouteEnter", to, from, next);
	},
	computed: 
	{
		authModel() 
		{
			return this.$store.state.auth
		},
		tradeModel() 
		{
			return this.$store.state.trade
		}
	},
}
</script>
