import axios from "axios";

const lib = {

is_frame: false,
	
isExists(value){
	return value != null && value != undefined;
},
	

/**
 * Получает cookie по названию
 */
getCookie(name)
{
	var matches = document.cookie.match(
		new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\/\+^])/g, "\$1") + "=([^;]*)"	)
	);
	return matches ? decodeURIComponent(matches[1]) : null; 
},



/**
 * Устанавливает cookie
 */
setCookie(name, value, expires, path, domain, secure)
{
	//console.log("setCookie " + name);
	if (expires == undefined) expires = 7*24*60*60;
	if (path == undefined) path = "/";
	var cookie_string = name+'='+escape(value);
	//if(expires) cookie_string += '; expires='+expires.toUTCString();
	if(path) cookie_string += '; path='+escape(path);
	if(domain) cookie_string += '; domain='+escape(domain);
	if(secure) cookie_string += '; secure';
	document.cookie = cookie_string;
},



/**
 * Удаляет куки
 */
deleteCookie(name)
{
	this.setCookie(name, '', -1);
},



/**
 * Парсит JWT токен на основе строки
 */
parseJwt(token) {
	let base64Url = token.split(".")[1];
	let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	
	//console.log("token", token);
	//console.log("base64", base64);
	
	try
	{
		if (typeof atob != "undefined") base64 = atob(base64);
		else base64 = (Buffer.from(base64, 'base64').toString());
		
		//console.log("atob(base64)", base64);
		
		let jsonPayload = decodeURIComponent(
			base64
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join("")
		);
		
		//console.log("jsonPayload", jsonPayload);
		
		return JSON.parse(jsonPayload);
	}
	catch (e)
	{
		
	}
	
	return null;
},



nl2br(str)
{
	return str.replace(/([^>])\n/g, '$1<br/>');
},



urlGetAddArr(url, arr, force)
{
	var url = new String(url);
	var pos = url.indexOf('#');
	if((typeof force) == 'undefined') force = 1;
	if(pos != -1){
		url = url.substring(0, pos);
	}

	for(var param in arr){
		var value = encodeURIComponent(arr[param]);
		var val = new RegExp('(\\?|\\&)'+param+'=.*?(?=(&|$))'), qstring = /\?.+$/;
		param = encodeURIComponent(param);
		if(val.test(url)){
			if(force == 1){
				if(value != ''){
					url = url.replace(val, '$1'+param+'='+value);
				} else {
					url = url.replace(val, '$1');
				}
			}
		} else if(qstring.test(url)){
			if(value != ''){
				url += '&'+param+'='+value;
			} else {
				url = url;
			}
		} else {
			if(value != ''){
				url += '?'+param+'='+value;
			} else {
				url = url;
			}
		}
	}
	return url;
},



urlGetAdd(url)
{
	var arr = {};
	var param, value, j = 0;
	for(var i = 1; i < arguments.length; i++){
		if(j == 0){
			param = arguments[i];
		} else {
			value = arguments[i];
			arr[param] = value;
			j = -1;
		}
		j++;
	}
	return this.urlGetAddArr(url, arr, 1);
},



urlGetAdd2(url)
{
	var arr = {};
	var param, value, j = 0;
	for(var i = 1; i < arguments.length; i++){
		if(j == 0){
			param = arguments[i];
		} else {
			value = arguments[i];
			if((typeof arr[param]) == 'undefined'){
				arr[param] = value;
			}
			j = -1;
		}
		j++;
	}
	return this.urlGetAddArr(url, arr, 0);
},



urlGetNormal(url){
	var url = new String(url);
	var s1 = new RegExp("\\&+", "g");
	var s2 = new RegExp("\\?\\&+", "g");
	var s3 = new RegExp("\\&$", "g");
	url = url.replace(s1, '&');
	url = url.replace(s2, '?');
	url = url.replace(s3, '');
	return url;
},



isUrl(s)
{
	var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
	return regexp.test(s);
},


/**
 * Добавляет value в FormData
 */
appendFormData(form, value, key)
{
	if (key == undefined)
	{
		key = "";
	}
    if (typeof value === 'object')
	{
		for (let i in value)
		{
			let v = value[i];
			if (key == '')
			{
				this.appendFormData(form, v, i);
			}
			else
			{
				this.appendFormData(form, v, key + '[' + i + ']');
			}
		}
    }
	else
	{
        form.append(key, value);
    }
},


/**
 * Вызывает API
 */
ioCallAction: async function(arr)
{
	var arr = arr || {};
	var entity = arr.entity || null;
	var resultType = arr.resultType || 'json';
	var resultNoParams = arr.resultNoParams || 0;
	var log_echo = arr.log_echo || 1;
	var params = arr.params || {};
	var form = arr.form || new FormData();
	
	this.appendFormData(form, {
		"entitypost": params,
	});
	
	if (entity == null)
	{
		return {
			"error_code": -1,
			"error_str": "Entity is null",
		};
	}
	
	/* Build url */
	var url = '/entity/' + entity + '/';
	url = this.urlGetAdd(url, 'resultType', resultType);
	url = this.urlGetAdd(url, 'resultNoParams', resultNoParams);
	if ((typeof app_settings["current_account"]) != 'undefined')
	{
		url = this.urlGetAdd(url, 'account', app_settings["current_account"]);
	}
	/* Send post */
	let response = await axios
		.post(
			url,
			form,
			{
				withCredentials: true,
				credentials: "include",
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			},
		);
	if (!response)
	{
		return {
			"error_code": -1,
			"error_str": "Response is null",
		};
	}
	//console.log('lib response', response)
	
	if (typeof(response.data) != "object")
	{
		return {
			"error_code": -1,
			"error_str": "Response data is not object",
		};
	}
	
	if (typeof(response.data.res) != "object")
	{
		return {
			"error_code": -1,
			"error_str": "Result is null",
		};
	}
	
	if (log_echo && response.data.content != "")
	{
		console.log( response.data.content );
	}
	
	return response.data.res;
},



/**
 * Создает blob файл из base64 string
 */
createBlobFromBase64: function (b64Data, contentType='', sliceSize=512)
{
	const byteCharacters = atob(b64Data);
	const byteArrays = [];
	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);
		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
		byteNumbers[i] = slice.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}
	const blob = new Blob(byteArrays, {type: contentType});
	return blob;
},


isIframe: function()
{
	return this.is_frame;
},


/* Mobile functions */
mobile: {
    
    isMobile: function()
    {
        return "android" == lib.getCookie("platform");
    },
    
	getPlatform: function()
	{
		return lib.mobile.isMobile() ? lib.getCookie("platform") : "desktop";
	},
	
	getVersion: function()
	{
		return lib.mobile.isMobile() ? lib.getCookie("platform_version") : "noapp";
	},
    
    prepareCallback: function(callback){
        callback = callback || function(){};
        callback = callback.toString();
        callback = callback.replace(/[\x00-\x1F]/g, " ");
        return callback;
    },
	
	vibrate: function()
	{
		if(lib.mobile.isMobile())
		{
			Application.vibrate();
		}
	},
	
	vibrateIframe: function()
	{
		parent.postMessage({
			'event_name': 'vibrate',
		}, '*');
	},
	
	setCurrentUrl: function(url)
	{
		if (lib.mobile.isMobile())
		{
			params = new Object();
			params.url = new String(url);
			var str = JSON.stringify(params);
			lib.mobile.setCurrentUrl(str);
		}
	},
    
    takePicture: function(params){
        if(lib.mobile.isMobile()){
            params = params || new Object();
            params.callback = lib.mobile.prepareCallback(params.callback);
            var str = JSON.stringify(params);
            Application.takePicture(str);
        }
    },
    
    uploadPicture: function(params){
        if(lib.mobile.isMobile()){
            params = params || new Object();
            params.callback = lib.mobile.prepareCallback(params.callback);
            var str = JSON.stringify(params);
            Application.uploadPicture(str);
        }
    },
    
    showPicture: function(params){
        if(lib.mobile.isMobile()){
            params = params || new Object();
            var str = JSON.stringify(params);
            Application.showPicture(str);
        }
    },
    
    callQRScanner: function(params){
        if(lib.mobile.isMobile()){
            params = params || new Object();
            params.callback = lib.mobile.prepareCallback(params.callback);
            var str = JSON.stringify(params);
            Application.callQRScanner(str);
        }
    },
    
    callQRScannerIframe: function(params)
	{
		let camera_params = JSON.parse(JSON.stringify(params));
		parent.postMessage({
			'event_name': 'qr_code_scanner',
			'params': camera_params
		}, '*');
	},
    
    resumeQRScanner: function(params){
        if(lib.mobile.isMobile()){
            Application.resumeQRScanner();
        }
    }
	
},
};

export default lib;