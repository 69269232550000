<template>
	<div class="container pt-4 text-center px-0">
		<div class="col">
			<GoodsTitle 
				value="goods:showItem"
			/>
			<modal-window title="Удаление товара" ref="thisModal">
				<template #body>
					<span>Вы уверены, что хотите удалить 
						товар <strong>{{goods.name_ru}}</strong>?</span>
				</template>
				<template #footer_btn_cancel>
					<span>
						Нет
					</span>
				</template>
				<template #footer_btn_ok>
					<button class="btn btn-primary btn-lg flex-fill" @click="confirmModal()">
						Да
					</button>
				</template>
			</modal-window>
			<div class="container d-grid gap-3 px-0">
				<!-- Photo -->
				<div class="col col-md-8 col-lg-6 mx-auto">
					<div class="col">
						<div class="col d-flex justify-content-center">
							<div class="col pb-4 rounded">
								<div class="mb-3 d-flex 
								justify-content-center align-items-center">
									<img
										src="/assets/kartofel-1.jpg"
										class="img-fluid border rounded shadow-sm goods_show_item__image"
										:class="image_loaded ? '' : 'd-none'"
										alt=""
										@load="imgOnload()"
									/>
									<div v-if="!image_loaded" class="loading-wheel"></div>
								</div>
								<div class="">
									<p class="text-center mb-0">
										{{goods.name_ru}}
									</p>
									<p class="text-center mb-0">
										{{goods.vendor_code}}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Buttons -->
				<div class="col w-100 px-3 pb-3 mx-auto">
					<div class="list-group gap-3">
						<div class="list-group-item d-flex p-0 border-0">
							<button
								class="btn btn-outline-primary flex-fill"
								@click="goTo('goods:edit:info')"
							>
								Редактировать товар
							</button>
						</div>
						<div class="list-group-item d-flex p-0 border-0">
							<button
								class="btn btn-outline-primary flex-fill"
								@click="goTo('goods:offer:add')"
							>
								Добавить новое предложение
							</button>
						</div>
						<div class="list-group-item d-flex p-0 border-0">
							<button
								class="btn btn-outline-primary flex-fill"
								@click="goTo('goods:offers:list')"
							>
								Все предложения
							</button>
						</div>
						<div class="list-group-item d-flex p-0 pt-5 border-0">
							<button 
								class="btn btn-danger flex-fill"
								@click="showModal()"
							>
								Удалить товар
							</button>
						</div>
					</div>
					<!-- errMsg -->
					<div
						class="col mx-3 errMsg d-flex align-items-center 
						rounded-3 p-2 border border-1 shadow-sm"
						v-if="errMsg"
					>
						<img
							src="/assets/info-circle-solid.svg"
							alt="info: "
							class="image-fluid me-2"
							style="width: 20px; height: 20px"
						/>
						{{ errMsg }}
					</div>
				</div>
			</div>
			<!-- Navigation Bottom Button -->
			<div class="col w-100 gx-0 p-3">
				<!-- hr -->
				<hr class="my-3">
				<div class="col d-flex justify-content-start">
					<router-link
						:to="{
							name: 'goods:list',
							query: {
								account: this.authModel.getCurrentAccount(),
								lang: this.$route.query.lang,
							},
						}"
						role="button"
						class="btn btn-outline-secondary d-flex align-items-center"
					>
						Назад
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
async function routeInit(type, to, from, next) {
	storeInstance.state.app.setLoadingState(true);
	storeInstance.state.trade.clearGoods();
	let goods_pkid = to.params.goods_pkid;
	let response = await storeInstance.state.trade.doLoadGoods(goods_pkid);
	if(storeInstance.state.trade.getGoods() != null
		|| storeInstance.state.trade.getGoods() != undefined)
	{
		let type_id = response.goods.type_id;
		storeInstance.state.trade.setTypeId(type_id);
	}

	storeInstance.state.app.setLoadingState(false);
	
	next(vm => {
		vm.goods = storeInstance.state.trade.goods
	});
}
export default {
	beforeRouteEnter(to, from, next) {
		routeInit("beforeRouteEnter", to, from, next);
	},
	beforeRouteUpdate(to, from, next) {
		routeInit("beforeRouteUpdate", to, from, next);
	},
	data()
	{
		return {
			goods: {},
			errMsg: "",
			image_loaded: false,
		}
	},
	updated()
	{
		window["onIframeClick"]()
	},
	methods:
	{
		imgOnload()
		{
			this.image_loaded = true
			window["onIframeClick"]()
		},
		showModal()
		{
			this.$refs.thisModal.openModal()
		},
		confirmModal()
		{
			this.$refs.thisModal.closeModal()
			this.goTo('goods:delete')
		},
		goTo(somewhere)
		{
			let params = {
				goods_pkid: this.tradeModel.getGoodsPkid(),
				type_id: this.tradeModel.getTypeId(),
			}
			
			let query = {
				account: this.authModel.getCurrentAccount(),
				lang: this.authModel.getSettingsMobiLang(),
			}
			
			if(somewhere == 'goods:edit:info')
			{
				this.$router.push({
						name: 'goods:edit:info',
						params: params,
						query: query
				})
			}
			else if(somewhere == 'goods:offer:add')
			{
				this.$router.push({
						name: 'goods:offer:add',
						params: params,
						query: query
				})
			}
			else if(somewhere == 'goods:offers:list')
			{
				this.$router.push({
						name: 'goods:offers:list',
						params: params,
						query: query
				})
			}
			else if(somewhere == 'goods:delete')
			{
				let response = this.tradeModel.deleteGoods()
				if(response)
				{
					this.$router.push({
							name: 'goods:list',
							query: query
					})
				}
				else
				{
					this.errMsg = 'Не удалось удалить товар.'
				}
			}
		}
	},
	computed: {
		authModel() {
			return this.$store.state.auth;
		},
		tradeModel() {
			return this.$store.state.trade;
		},
	},
};
</script>

<style lang="scss" scoped>
.goods_show_item {
	&__image {
		height: 100px;
	}
}
</style>
